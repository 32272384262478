import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
  selector: 'form-field-checkbox',
  template: `
    <label [attr.for]="inputId">
      <input
        #checkboxInput
        type="checkbox"
        class="visuallyhidden"
        tabindex="-1"
        [id]="inputId"
        [checked]="checked"
        [attr.aria-checked]="checked"
        [disabled]="disabled"
        (change)="setValue($event.target.checked)"
      />
      <span tabindex="-1" class="checkmark" [attr.aria-label]="checked ? 'Cochée' : 'Décochée'"></span>
      <ng-content></ng-content>
    </label>
  `,
  styleUrls: ['./form-field-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldCheckboxComponent),
      multi: true,
    },
  ],
})
export class FormFieldCheckboxComponent implements ControlValueAccessor {
  @HostBinding('class.checked')
  @Input()
  set checked(value: boolean) {
    this.setValue(coerceBooleanProperty(value));
  }
  get checked(): boolean {
    return this._checked;
  }
  private _checked = false;

  @HostBinding('class.disabled')
  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(coerceBooleanProperty(value));
  }
  get disabled(): boolean {
    return this._disabled;
  }
  private _disabled = false;

  @HostBinding('attr.id')
  @Input()
  id: string;

  @Output()
  valueChanged = new EventEmitter<boolean>();

  @ViewChild('checkboxInput', { static: true })
  checkboxInput: ElementRef<HTMLInputElement>;

  @HostBinding('attr.tabindex')
  get tabindex(): number {
    return this.disabled ? -1 : 0;
  }

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  private _uniqueId = `ui-checkbox-${++nextUniqueId}`;

  private onChangeFn: (value: boolean) => void = () => {};
  private onTouchedFn: () => void = () => {};

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    if (value !== undefined) {
      this.checked = value;
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  handleToggle(event: Event): void {
    if (!this.checkboxInput) {
      return;
    }
    if (event.target === this.checkboxInput.nativeElement) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.toggle();
  }

  setValue(value: boolean): void {
    if (value === this.checked) {
      return;
    }

    this._checked = value;

    this.onTouchedFn();
    this.onChangeFn(value);
    this.valueChanged.emit(value);
  }

  toggle(): void {
    if (!this.disabled) {
      this.checked = !this.checked;
    }
  }
}
