const imageWithMobileFragment = `
fragment imageWithMobileFragment on ImageWithMobile {
  url
  alt
  mobile {
    url
    alt
  }
}
`;

const buttonFragment = `
fragment buttonFragment on Button {
  label
  url
  target
  type
  button_color
  label_color
  label_size
}
`;

const faqQuestionFragment = `
fragment faqQuestionFragment on FaqQuestion {
  name
  text
  answer
}
`;

const contactUsBlockFragment = `
fragment contactUsBlockFragment on ContactUsBlock {
  title
  elements {
    image {
      url
      alt
    }
    text
    quote
    items {
      text
      phone
      image {
        url
        alt
      }
    }
  }
}
`;

const tableauObjetComparatif = `
 {
    titre_tableau
    couleur_lisere
    couleur_fond_zone_1
    texte_lisere
    couleur_lisere_2
    tableau_picto_zone_1 {
      picto_zone { ...imageWithMobileFragment }
      picto_texte
    }
    couleur_fond_zone_2
    tableau_picto_zone_2 {
      picto_zone { ...imageWithMobileFragment }
      picto_texte
    }
    titre_promo
    texte_promo
    prix_offre
    description_tableau
    libelle_bouton
    url_bouton {
      url
      target
    }
    sous_titre_tableau
    avantage_offre {
      picto_avantage { ...imageWithMobileFragment }
      libelle_avantage
      statut_avantage
    }
    libelle_lien
    url_lien {
      url
      target
    }
}
`;

const text = `
  {
    text
    spans {
      start
      end
      type
      data {
        url
        target
        label
        isBroken
        link_type
      }
    }
}`;

const superHero = `
{
  title
  title_color
  title_size
  subtitle
  subtitle_color
  subtitle_size
  label_size
  label_color
  richText
  text_color
  text_size
  image { ...imageWithMobileFragment }
  buttons {
    label
    url
    button_color
    label_color
    label_size
    target
  }
  campaignCode
  persoContext
  megaHero
}`;

const landingPageEventOfferItem = `
{
  activeCard { ...imageWithMobileFragment }
  inactiveCard { ...imageWithMobileFragment }
  image { ...imageWithMobileFragment }
  creditMention
  title,
  subtitle,
  description
  characteristics
  contactArea
  contactAreaPhoneNumber
  contactMention
  contactButtonLabel
  contactButtonURL
  contactLinkLabel
  contactLinkURL
  exampleCreditTitle
  exampleCreditText,
  legalMention
}
`;

const family_products = `
{
  type_name
  iframe {
    uid
    link_type
    url
  }
  products {
    product_code
    product_name
    informations
    optional_reference
    help_optional_reference
    resiliation_date
  }
}
`;

const bodySlices = `
body {
  type
  ... on ContactsBlockSlice {
    title
    image { ...imageWithMobileFragment }
    contacts {
      name
      phone
      email
    }
  }
  ... on CreditExampleSlice {
    title
    richText
    legal
  }
  ... on QuoteSlice {
    title
    signature
    richText
  }
  ... on NewsroomSlice {
    title
    newsroomItems {
      title
      link {
        url
        target
      }
      image { ...imageWithMobileFragment }
    }
    button { ...buttonFragment }
    seeMoreLabel
  }
  ... on ParagraphSlice {
    alignment
    richText
  }
  ... on VideoSlice {
    title
    anchor
    videoUrl
    videoProvider
  }
  ... on NewsblocSlice {
    newsType
    newsTitle
    displayMode
    seeMoreNewsLabel
    articles {
      title
      type
      readingTime
      image {
        url
        alt
      }
      publishOn
      url
      subThematic
    }
    subThematics {
      thematicId
      subThematicLibele
      prismicSubThematicLibele
    }
  }
  ... on PushArticlesSlice {
    title
    pushArticles {
      title
      type
      readingTime
      image {
        url
        alt
      }
      publishOn
      url
      description
    }
  }
  ... on ButtonSlice {
    buttons {
      label
      url
      target
    }
  }
  ... on SectionTextSlice {
    title
    description
    button { ...buttonFragment }
    link { ...buttonFragment }
    subtitle
    image { ...imageWithMobileFragment }
    isLandingPageIframe
  }
  ... on LegalMentionsSlice {
    richText
  }
  ... on NoteSlice {
    notes {
      title
      richText
    }
  }
  ... on MentionCreditSlice {
    description
  }
  ... on CharacteristicsKeysSlice {
    title
    characteristicsKeys {
      title
      text
    }
  }
  ... on CharacteristicsSlice {
    title
    description
  }
  ... on ArgumentsSlice {
    title
    button { ...buttonFragment }
    hasBackgroundColor
    argumentItems {
      subtitle
      text
      link {
        url
        target
      }
      image { ...imageWithMobileFragment }
    }
  }
  ... on TableSlice {
    title
    subtitle
    anchor
    tableTitles {
      id
      label
      description
    }
    tableContent
    tableCategories
  }
  ... on OffersCarouselSlice {
    title
    offerItems {
      title
      image { ...imageWithMobileFragment }
      link {
        url
        target
      }
      campaignCode
      vignette {
        name
        valueNumber
        valueUnit
        valueSubtext
      }
    }
  }
  ... on HeroSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
    description
    buttons { ...buttonFragment }
  }
  ... on SuperHeroSlice {
    title
    subtitle
    richText
    image { ...imageWithMobileFragment }
    buttons {
      label
      url
      target
      button_color
      label_color
      label_size
    }
    campaignCode
    persoContext
    megaHero
  }
  ... on MagHeroSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
    articles {
      title
      type
      readingTime
      publishOn
      url
    }
  }

  ... on AgencyMapHeroSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
  }

  ... on AgencyDetailsSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
  }

  ... on ProjectBlockCtaCSlice {
    title
    projectBlocks {
      title
      richText
      image { ...imageWithMobileFragment }
      button { ...buttonFragment }
      anchor
      anchorLabel
      size
    }
  }
  ... on PushLinksSlice {
    title
    description
    image { ...imageWithMobileFragment }
    links {
      label
      url
      target
    }
  }
  ... on PushProductsSlice {
    title
    description
    image { ...imageWithMobileFragment }
    pushProductsGroups {
      title
      products {
        label
        description
        url
      }
    }
  }
  ... on StepperSlice {
    title
    subtitle
    button { ...buttonFragment }
    steps
  }
  ... on PdfLinksSlice {
    alignment
    items {
      label
      type
      url
      target
    }
  }
  ... on AppsAdSlice {
    title
    anchor
    appsAdImages {
      app {
        alt
        url
      }
      year {
        alt
        url
      }
      picture {
        alt
        url
      }
    }
    description
    appStores {
      link {
        label
        url
        target
      }
      image {
        url
        alt
      }
    }
  }
  ... on SimulatorSlice {
    simulatorType
    url
    mobileIsDifferent
    description
    height
  }
  ... on SolutionProductsSlice {
    title
    solutionProducts {
      title
      description
      anchor
      anchorLabel
      imageSize
      image { ...imageWithMobileFragment }
      linkedProducts {
        title
        description
        url
        simulatorLink {
          label
          url
          target
        }
      }
      complementContact
    }
  }
  ... on MultiLinksSlice {
    title
    subtitle
    simulatorDirectories {
      image {
        url
        alt
        dimensions {
          height
          width
        }
      }
      category
      blocks {
        title
        links {
          label
          url
          target
        }
      }
    }
  }
  ... on CarouselServicesSlice {
    title
    carouselServicesItems {
      title
      description
      image {
        url
        mobile {
          url
        }
      }
      animation {
        url
        mobile {
          url
        }
      }
      campaignCode
    }
  }
  ... on PushParcoursSlice {
    title
    subtitle
    button { ...buttonFragment }
    image { ...imageWithMobileFragment }
    richText
  }
  ... on FrontPageNewsSlice {
    title
    subtitle
    description
    mobileDescription
    link {
      label
      url
      target
    }
    image { ...imageWithMobileFragment }
  }
  ... on ContactUsSlice {
    contactUsTabs {
      type
      title
      leftBlocks { ...contactUsBlockFragment }
      rightBlocks { ...contactUsBlockFragment }
      questionsBlock {
        title
        questions { ...faqQuestionFragment }
        button { ...buttonFragment }
      }
    }
  }
  ... on SummarySlice {
    title
    richText
  }
  ... on SitemapSlice {
    sitemapColumns {
      title
      univers {
        label
        url
        products {
          label
          url
        }
      }
    }
  }
  ... on OfferBlockSlice {
    anchor
    offerBlock {
      campaignCode
      name
      longTitle
      conditions
      datePreText
      dateText
      valueNumber
      valueUnit
      valueSubtext
    }
    button { ...buttonFragment }
    offerPhone {
      phone
      subtext
    }
  }
  ... on FaqAccordionSlice {
    anchor
    questions { ...faqQuestionFragment }
  }
  ... on InterstitialChoicesSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
    buttons {
      label
      url
      target
    }
  }
  ... on NonCustomerComplaintSlice {
    title
  }
  ... on EntrepriseContactFormSlice {
    title
  }
  ... on ProContactFormSlice {
    title
  }
  ... on AssistanceFormSlice {
    title
  }
  ... on ReclamationFormSlice {
    title
    recipt ${text}
    checkbox_reclamation_1
    checkbox_reclamation_2
    title_placeholder_relation
    select_relation
    title_placeholder_client_type
    select_type
    lien_client
    title_placeholder_message
    liste_message
    under_jointpiece
    notice_rgpd
    email_redirection
  }
  ... on NewAngleFormSlice {
    title
    recipt ${text}
    title_placeholder_relation
    select_relation
    title_placeholder_client_type
    select_client_type
    text_radio_button
    text_rgpd
    email_redirection
  }
  ... on LoanInsuranceFormSlice {
    title
  }
  ... on ReclamationConnectedCustomerFormSlice {
    title
  }
  ... on ResiliationFormSlice {
    title
    recipt ${text}
    product_types ${family_products}
  }
  ... on QuestionFormSlice {
    title
    recipt ${text}
    titre_placeholder_relation
    select_relation
    titre_placeholder_client_type
    select_client_type
    lien_client
    title_placeholder_message
    liste_message
    under_jointpiece
    text_rgpd
    email_redirection
  }
  ... on LinksProductsBlockSlice {
    title
    linkProductBlockItems {
      description
      label
      url
    }
    link {
      label
      url
      target
      type
    }
    button { ...buttonFragment }
  }
  ... on TimelineSlice {
    title
    timelineSteps {
      title
      description
      link {
        url
        target
      }
      desktopImage {
        url
        alt
      }
      mobileImage {
        url
        alt
      }
      mobileAdditionalImage {
        url
        alt
      }
    }
    action {
      title
      description
      button { ...buttonFragment }
    }
  }
  ... on InteractiveQuestionsSlice {
    interactiveQuestionsBlock {
      title
      backgroundUrl
      firstQuestionId
      questions {
        id
        label
        answers {
          label
          action
          value
        }
      }
    }
  }
  ... on SocialEmbedsSlice {
    title
    socialEmbeds {
      html
      provider
    }
  }
  ... on LandingPageHeroSlice {
    image { ...imageWithMobileFragment }
    title
    subtitle
    buttonPrimary { ...buttonFragment }
    buttonSecondary { ...buttonFragment }
  }
  ... on LandingPageBlockType1Slice {
    image { ...imageWithMobileFragment }
    imageOnLeft
    title
    description
    button { ...buttonFragment }
  }
  ... on LandingPageBlockType2Slice {
    image { ...imageWithMobileFragment }
    imageOnLeft
    title
    description
    button { ...buttonFragment }
  }
  ... on LandingPageKeyInfoSlice {
    content
  }
  ... on LandingPageEventHeroSlice {
    title
    subtitle
    accroche
    event_banner { ...imageWithMobileFragment }
    event_logo { ...imageWithMobileFragment }
    logo_link {
      label
      url
      target
      type
    }
  }
  ... on ProductHeroSlice {
    title
    subtitle
    image { ...imageWithMobileFragment }
    description
    button { ...buttonFragment }
  }

  ... on LandingUniversGalSlice {
    title
    subtitle
    richText
    image { ...imageWithMobileFragment }
    buttons {
      label
      url
      target
    }
    megaHero
  }

  ... on LandingPageEventOfferListSlice {
    offers ${landingPageEventOfferItem}
  }

  ... on ScratchAreaSlice {
    title
    description
    button { ...buttonFragment }
    link { ...buttonFragment }
    subtitle
    image { ...imageWithMobileFragment }
  }

  ... on ZoneComparativeSlice {
    image_fond { ...imageWithMobileFragment }
    tableau_comparatif ${tableauObjetComparatif}
  }

  ... on HeroCarouselSlice {
    timer
    items {
      hero ${superHero}
    }

  }

  ... on LifeMomentSlice {
    title_bloc ${text}
    color_title
    image { ...imageWithMobileFragment }
    color_label
    color_icon
    color_button
    blocLinks {
      label
      link {
        url
        target
      }
    }
  }

}
`;

export const cmsPageBydId = `

  ${imageWithMobileFragment}

  ${buttonFragment}

  ${contactUsBlockFragment}

  ${faqQuestionFragment}

  query CmsPageByIdQuery(
    $documentId: String!
  ) {
    pageById(documentId: $documentId) {
      id
      uid
      type
      data {
        pageContent {
          ${bodySlices}
        }
      }
    }
  }
`;

export const agency = `
   {
    id
    name
    address
    postalCode
    town
    country
    phoneNumber
    fax
    agencyType
    hasVault
    latitude
    longitude
    distance
    timezone
    hours {
      monday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      tuesday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      wednesday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      thursday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      friday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      saturday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
      sunday { isOpen openingHours { amOpen amClose pmOpen pmClose } }
    }
    closedDates {
      begin
      end
    }
    communicationArea
  }`;

export const agenciesQueryString = `
query agencyQuery(
  $query: String,
  $latitude: Float,
  $longitude: Float,
  $hasVault: Boolean,
  $isOpenSaturday: Boolean,
  $isPrivateBank: Boolean,
  $isProBank: Boolean
) {
  agencies(
    query: $query,
    latitude: $latitude,
    longitude: $longitude,
    hasVault: $hasVault,
    isOpenSaturday: $isOpenSaturday,
    isPrivateBank: $isPrivateBank,
    isProBank: $isProBank
  ) {
    number
    agencies ${agency}
  }
 }
`;

export const agencyDetailsQueryString = `
query agencyDetailsQuery(
  $agencyId: String,
) {
  agency(
    agencyId: $agencyId,
  ) ${agency}
 }
`;

export const cmsPageByTypesAndUidQueryString = `

  ${imageWithMobileFragment}

  ${buttonFragment}

  ${contactUsBlockFragment}

  ${faqQuestionFragment}

  query CmsPageByTypesAndUidQuery(
    $types: [String]!, $uid: String!, $filters: PageFilters, $iframe: Boolean) {
    pageByTypesAndUid(
      types: $types, uid: $uid, filters: $filters, iframe: $iframe) {
      id
      uid
      url
      type
      data {
        breadcrumb {
          links {
            label
            url
          }
        }
        market
        universe
        image {
          dimensions {
            height
            width
          }
          alt
          url
        }
        seo {
          title
          description
          robots
          canonical
          openGraph {
            title
            description
            image {
              url
            }
            url
            type
          }
        }
        homepageId
        pageContent {
          ${bodySlices}
          ... on SimplePage {
            title
            subtitle
            backButtonLabel
            backButtonLink {
              label
              url
              target
            }
          }
          ... on FaqPage {
            title
            text
            questionsTitle
            questions { ...faqQuestionFragment }
            image {
              url
              alt
            }
          }
          ... on Article {
            head {
              title
              type
              readingTime
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              description
              publishOn
              pdfDownloadLink
              shareList
            }
          }
        }
        pushBanner {
          title
          link {
            label
            url
            target
          }
        }
      }
      lang
      alternateLanguages {
        id
        uid
        type
        lang
      }
    }
  }
`;
