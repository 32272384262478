import { SliceType } from '@repo/shared';

export const SLICE_TAGGING_NAME: Record<SliceType, string> = {
  paragraph: 'Paragraphe',
  quote: 'Citation',
  notes: 'NotesRenvois',
  contacts_presse: 'ContactsPresse',
  newsroom: 'NewsroomThematiques',
  newsbloc: 'BlocActus',
  button: 'Bouton',
  video: 'Video',
  section_text: 'SectionTexte',
  arguments: 'Argumentaire',
  mention_credit: 'MentionCredit',
  legal_mentions: 'MentionsLégales',
  credit_example: 'ExempleCrédit',
  characteristics_keys: 'CaractéritistiquesClés',
  characteristics: 'Caractéristiques',
  hero: 'Hero',
  super_hero: 'Hero',
  project_block_cta: 'ProjetsCTA',
  push_products: 'PushProduitsMultiple',
  stepper: 'Etapier',
  pdf_links: 'LiensPDF',
  table: 'Comparateur',
  apps_ad: 'PushMobile',
  solution_products: 'SolutionsProduits',
  multi_links: 'SimulateurMultiLiens',
  offers_carousel: 'CarrouselOffres',
  simulator: 'Simulateur',
  carousel_services: 'CarrouselServices',
  push_articles: 'PushArticles',
  push_parcours: 'PushParcours',
  front_page_news: 'ALaUne',
  contact_us: 'NousContacter',
  agency_map_hero: 'CarteAgenceHero',
  agency_details: 'AgencyDetails',
  summary: 'Resume',
  sitemap: 'PlanSite',
  offer_block: 'Promo',
  faq_accordion: 'QuestionFAQ',
  interstitial_choices: 'InterstitielEtape1',
  push_links: 'FAQListeThematiques',
  non_customer_complaint: 'FormReclamationNonClient',
  entreprise_contact_form: 'FormContactEntreprise',
  pro_contact_form: 'FormContactPro',
  assistance_form: 'FormAssistance',
  reclamation_form: 'FormReclamation',
  angle_neuf: 'FormNewAngleForm',
  loan_insurance_form: 'FormLoanInsurance',
  reclamation_connected_customer_form: 'FormReclamationClientConnecté',
  resiliation1: 'FormResiliation',
  poser_question: 'FormQuestion',
  links_products_block: 'LiensProduits',
  timeline: 'Timeline',
  interactive_questions: 'Questionnaire',
  mag_hero: 'HeroMag',
  social_embeds: '(NotUsed)',
  landing_page_hero: 'HeroLP',
  landing_page_event_hero: 'HeroLPEvent',
  landing_page_event_offer_list: 'LPEventOffres',
  landing_page_block_type1: 'LisereJaune',
  landing_page_block_type2: 'AplatBleu',
  landing_page_key_info: 'InformationClé',
  page_produit_gal: 'PageProduitGal',
  landing_univers_gal: 'LandingUniversGal',
  scratch_area: 'ScratchingArea',
  zone_comparative: 'ZoneComparative',
  hero_carousel: 'HeroCarousel',
  bloc_moment_de_vie: 'LifeMoment',
};

export const SLICE_TAGGING_SUBPATH: Record<string, string> = {
  resiliation_form: 'resiliation::formulaire',
  resiliation_confirmation: 'resiliation::confirmation',
};
