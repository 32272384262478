import { Component, Input } from '@angular/core';
import { SliceWithData } from '../../../../typings';
import { VideoProvider, VideoSlice } from '@repo/shared';

@Component({
  selector: 'slice-video',
  template: `
    <ng-container *ngIf="isValid()">
      <div *ngIf="data?.title" class="bloc-text withFrame" [attr.id]="data?.anchor">
        <h2 class="title">{{ data.title }}</h2>
      </div>
      <div class="slice-video">
        <iframe
          *ngIf="isStreamlike()"
          [src]="data.videoUrl | trustResourceUrl"
          frameborder="0"
          marginheight=""
          marginwidth=""
          scrolling="no"
          align="top"
        >
        </iframe>
        <iframe
          *ngIf="isYoutube()"
          [src]="data.videoUrl | trustResourceUrl"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        >
        </iframe>
      </div>
    </ng-container>
  `,
  styleUrls: ['./slice-video.component.scss'],
})
export class SliceVideoComponent implements SliceWithData<VideoSlice> {
  @Input() data: VideoSlice;
  @Input() isFirstSlice: boolean;
  @Input() sliceIndex: number;

  isValid(): boolean {
    return !!(this.data?.videoUrl && this.data.videoProvider) && (this.isStreamlike() || this.isYoutube());
  }

  isStreamlike(): boolean {
    return this.data.videoProvider === VideoProvider.Streamlike;
  }

  isYoutube(): boolean {
    return this.data.videoProvider === VideoProvider.Youtube;
  }
}
