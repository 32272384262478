import { JSONSchema7 } from 'json-schema';
import { emailRegex, Form, nameRegex } from './form';
import { Text } from '../index';

export interface NewAngleForm extends Form {
  clientFunction: string;
  clientCategory: string;
  agencyRef: string;
  agencyQuery: string;
  agencyID: string;
  postalCode?: string;
  emailRedirection: string;
  recipt: Text[];
}

export const CLIENT_RELATION = {
  LCL_CLIENT: 0,
  NON_LCL_CLIENT: 1,
};

export const OPTIONS_CLIENT_CATEGORIES = ['Particulier', 'Professionnel', 'Banque privée'];

export const CLIENT_FUNCTION = ['Je suis client', 'Je ne suis pas client'];

export const NEW_ANGLE_FORM: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    postalCode: {
      type: 'string',
      minLength: 1,
      pattern: '^$|^[0-9]{5}$',
      maxLength: 5,
    },
    clientFunction: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_FUNCTION.length - 1,
    },
    clientCategory: {
      type: 'integer',
      minimum: 0,
      maximum: OPTIONS_CLIENT_CATEGORIES.length - 1,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    emailRedirection: {
      type: 'string',
      minLength: 1,
    },
    textRadioButton: {
      type: 'boolean',
      enum: [true],
    },
    recipt: {
      type: 'array',
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'phone',
    'postalCode',
    'clientFunction',
    'clientCategory',
    'agencyRef',
    'agencyQuery',
    'message',
    'recaptcha',
    'emailRedirection',
    'textRadioButton',
  ],
};

export const NEW_ANGLE_FORM_BACKEND_VALIDATION_SCHEMA: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    clientFunction: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_FUNCTION.length - 1,
    },
    clientCategory: {
      type: 'integer',
      minimum: 0,
      maximum: OPTIONS_CLIENT_CATEGORIES.length - 1,
    },
    agencyRef: {
      type: 'string',
      pattern: '^[0-9]{5}$',
      minLength: 1,
    },
    agencyQuery: {
      type: 'string',
      minLength: 1,
    },
    agencyID: {
      type: 'string',
      pattern: '^$|^[0-9]{5}$',
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    emailRedirection: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'phone',
    'clientFunction',
    'clientCategory',
    'agencyRef',
    'agencyQuery',
    'message',
    'recaptcha',
    'emailRedirection',
  ],
};
export const NEW_ANGLE_NON_CLIENT_FORM_BACKEND_VALIDATION_SCHEMA: JSONSchema7 = {
  properties: {
    civilTitle: {
      type: 'string',
      enum: ['M', 'Mme'],
      minLength: 1,
    },
    firstName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    lastName: {
      type: 'string',
      pattern: nameRegex,
      minLength: 1,
    },
    email: {
      type: 'string',
      pattern: emailRegex,
      minLength: 1,
    },
    phone: {
      type: 'string',
      pattern: '^$|^[0-9]{10}$',
      minLength: 1,
    },
    clientFunction: {
      type: 'integer',
      minimum: 0,
      maximum: CLIENT_FUNCTION.length - 1,
    },
    message: {
      type: 'string',
      minLength: 1,
      maxLength: 700,
    },
    recaptcha: {
      type: 'string',
      minLength: 1,
    },
    emailRedirection: {
      type: 'string',
      minLength: 1,
    },
  },
  required: [
    'civilTitle',
    'firstName',
    'lastName',
    'email',
    'phone',
    'clientFunction',
    'message',
    'recaptcha',
    'emailRedirection',
  ],
};
