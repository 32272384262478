import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { JSONSchema7 } from 'json-schema';
import { FORM_COMPONENT, VALIDATION_SCHEMA_CONFIG } from '../../../../../../tokens';
import { ECHEANCE_CATEGORIES, RESILIATION_REQUEST_FORM } from '@repo/shared/form-schemas/resiliation-client';
import { DOMService } from '../../../../../services/dom.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '../../abstract-form-component';
import { CdkStepperComponent } from '../../cdk-stepper/cdk-stepper.component';

import { ConfigService } from '../../../../../config.service';
import { SliceFormsService } from '../../slice-forms.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../logger/logger.service';
import { Logger } from '../../../../../logger/logger';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FileUploadComponent } from '../../../../../ui/molecules/form-upload-file/form-upload-file.component';
import { Produit } from '@repo/shared';
import { SLICE_TAGGING_NAME, SLICE_TAGGING_SUBPATH } from '../../../../../common/constants/tagging';

@Component({
  selector: 'resiliation-request',
  templateUrl: 'resiliation-request.component.html',
  styleUrls: ['../../slice-forms.scss', './resiliation-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: RESILIATION_REQUEST_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => ResiliationRequestComponent),
    },
  ],
})
export class ResiliationRequestComponent extends AbstractFormComponent implements AfterViewInit, OnChanges {
  @Input() regForm: FormGroup;
  @Input() productSelected: Produit;
  @Input() recipt: Text[];
  @Input() selectedIndex: number;

  logger: Logger;

  deadlineOptions = ECHEANCE_CATEGORIES;
  _buttonDisabled = true;
  _hasPostError = false;
  selectError = false;

  tagName: string;
  tagSubPath = SLICE_TAGGING_SUBPATH.resiliation_form;

  @ViewChild(FileUploadComponent, { static: true }) fileUploadComponent;

  constructor(
    readonly myStepper: CdkStepperComponent,
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _resiliationFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    readonly loggerService: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly domService: DOMService,
    private readonly configService: ConfigService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_resiliationFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(ResiliationRequestComponent.name);
  }

  ngAfterViewInit(): void {
    const requestGroup = this.regForm.get('request') as FormGroup;
    requestGroup.addControl('recipt', new FormControl(this.recipt, Validators.required));
    requestGroup.addControl('recaptcha', new FormControl(null));
    requestGroup.statusChanges.subscribe(_ => {
      this._buttonDisabled = !this.checkFormValidity();
    });
    requestGroup.get('deadline')?.valueChanges.subscribe(() => {
      this.regForm.get('request.resiliationDate')?.patchValue('');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIndex) {
      this.tagName = `${SLICE_TAGGING_NAME.resiliation1}_bt_suivant_etape_${this.selectedIndex + 1}`;
    }
  }

  previous(): void {
    this.myStepper.previous();
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('entrepriseRequestForm').subscribe(
      token => {
        this.regForm.get('request')?.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  checkSelectedDeadline(): boolean {
    return this.regForm.get('request.deadline')?.value === 'A la date du';
  }

  checkFormValidity(): boolean {
    const deadlineFormControl = this.regForm.get('request.deadline');
    if (this.checkSelectedDeadline()) {
      return deadlineFormControl?.status === 'VALID' && this.regForm.get('request.resiliationDate')?.status === 'VALID';
    } else {
      return deadlineFormControl?.status === 'VALID';
    }
  }

  submitForm(): void {
    if (this.checkFormValidity()) {
      this.myStepper.next();
    }
  }
}
