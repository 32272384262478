// region Enum

export enum YesNo {
  Yes = 'Oui',
  No = 'Non',
}

export enum LinkTarget {
  Self = '_self',
  Blank = '_blank',
  ExternalLcl = '_lcl',
  Interstitial = 'interstitial',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum VideoProvider {
  Streamlike = 'Streamlike',
  Youtube = 'Youtube',
}

export enum ArticleShareType {
  Print = 'impression',
  Download = 'telechargement',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Mail = 'mail',
}

export enum OpenGraphType {
  Website = 'website',
}

export enum PrismicBlocSizeType {
  Small = 'Petite',
  Large = 'Grande',
}

export enum BlocSizeType {
  Large = 'large',
  Small = 'small',
  None = 'none',
}

export enum SimulatorType {
  CACF = 'Crédit conso CACF',
  Harvest = 'Epargne Harvest',
  Pacifica = 'Assurances Pacifica',
  Nexecur = 'Nexecur',
}

export enum ValueUnit {
  Percent = '%',
  Euro = '€',
  Month = 'month',
  Aze = 'aze',
}

export enum LinkType {
  Document = 'Document',
  Web = 'Web',
  Media = 'Media',
}

export enum PrismicLinkType {
  CTA = 'Bouton CTA',
  product = 'Lien vers une fiche produit',
}

export enum LogLevel {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
}

export enum MegamenuItemType {
  LandingUnivers = 'landing_univers',
  HomeMag = 'home_mag',
}

export enum EmbedProvider {
  Twitter = 'Twitter',
  TikTok = 'TikTok',
}

// endregion

// region Type

export type PageType =
  | 'landing_universe'
  | 'product'
  | 'page_simple'
  | 'page_simple_institutional'
  | 'pagefaq'
  | 'homepage_institutional'
  | 'article'
  | 'newsroom_institutional'
  | 'simulators_and_estimations'
  | 'simulator_encapsulated'
  | 'homepage_particuliers'
  | 'homepage_mag'
  | 'contact'
  | 'sitemap'
  | 'thematic_faq'
  | 'thematic_faq_free'
  | 'interstitial'
  | 'form'
  | 'landing_page_step'
  | 'page_timeline'
  | 'mag_thematic_page'
  | 'landing_page_campaign'
  | 'landing_page_event'
  | 'home_agence'
  | 'home_agence_details'
  | 'page_produit_gal'
  | 'landing_univers_gal'
  | 'landing_page_event_offer_list'
  | 'landing_page_block_type1'
  | 'landing_page_block_type2';

export type SpanType = 'strong' | 'em' | 'hyperlink' | 'label' | 'list-item';

export type PrismicArticleType = 'Article' | 'Communiqué de presse' | 'Vidéo' | 'Infographie';

export type SliceType =
  | 'paragraph'
  | 'quote'
  | 'notes'
  | 'contacts_presse'
  | 'newsroom'
  | 'newsbloc'
  | 'button'
  | 'video'
  | 'section_text'
  | 'arguments'
  | 'mention_credit'
  | 'legal_mentions'
  | 'credit_example'
  | 'characteristics_keys'
  | 'characteristics'
  | 'hero'
  | 'super_hero'
  | 'mag_hero'
  | 'project_block_cta'
  | 'push_links'
  | 'push_products'
  | 'stepper'
  | 'pdf_links'
  | 'table'
  | 'apps_ad'
  | 'solution_products'
  | 'pdf_links'
  | 'multi_links'
  | 'offers_carousel'
  | 'simulator'
  | 'carousel_services'
  | 'push_articles'
  | 'push_parcours'
  | 'front_page_news'
  | 'contact_us'
  | 'summary'
  | 'sitemap'
  | 'offer_block'
  | 'faq_accordion'
  | 'interstitial_choices'
  | 'non_customer_complaint'
  | 'links_products_block'
  | 'timeline'
  | 'interactive_questions'
  | 'social_embeds'
  | 'entreprise_contact_form'
  | 'pro_contact_form'
  | 'assistance_form'
  | 'reclamation_form'
  | 'landing_page_hero'
  | 'landing_page_block_type1'
  | 'landing_page_block_type2'
  | 'landing_page_key_info'
  | 'agency_map_hero'
  | 'landing_page_event_hero'
  | 'landing_page_event_offer_list'
  | 'agency_details'
  | 'page_produit_gal'
  | 'landing_univers_gal'
  | 'scratch_area'
  | 'loan_insurance_form'
  | 'reclamation_connected_customer_form'
  | 'resiliation1'
  | 'poser_question'
  | 'zone_comparative'
  | 'hero_carousel'
  | 'angle_neuf'
  | 'bloc_moment_de_vie';

export interface PrismicNewsroomItem {
  image_element_newsroom: ImageWithMobile;
  url_element_newsroom: PrismicLink;
  titre_element_newsroom: string;
}

export type SliceItem =
  | Item
  | ParagraphItem
  | NoteItem
  | PrismicNewsroomItem
  | PrismicButtonLink
  | PrismicArgumentItem
  | CharacteristicsKeys
  | CharacteristicsContent
  | PrismicPushProductItem
  | PrismicSolutionProductItem
  | PrismicOffer
  | TableauObjetComparatif;

export type ArticleType = 'infographics' | 'video' | 'press' | 'article';

export type PageContentType = 'SimplePage' | 'DynamicPage' | 'FaqPage' | 'Article';

export type PrismicDataContactList = Partial<PrismicDataContactItem>[];

export type LogLevelType = keyof typeof LogLevel;

export type PrismicButtonType = 'page du site' | 'contact';

// endregion

// region Slice

export interface PrismicSlice<T = any, K = any[]> {
  slice_type?: string;
  primary?: T;
  items?: K;
}

export interface Slice {
  type: SliceType;
}

export interface ContactsBlockSlice extends Slice {
  type: 'contacts_presse';
  title?: string;
  image: ImageWithMobile;
  contacts?: ContactsBlockIem[];
}

export interface CreditExampleSlice extends Slice {
  type: 'credit_example';
  title?: string;
  richText?: string;
  legal?: string;
}

export interface QuoteSlice extends Slice {
  type: 'quote';
  title: string;
  signature: string;
  richText: string;
}

export interface NewsroomSlice extends Slice {
  type: 'newsroom';
  newsroomItems?: NewsroomItem[];
  title?: string;
  button?: Link;
  seeMoreLabel?: string;
}

export interface ParagraphSlice extends Slice {
  type: 'paragraph';
  alignment: Alignment;
  richText: string;
}

export interface VideoSlice extends Slice {
  type: 'video';
  title?: string;
  anchor?: string;
  videoUrl: string;
  videoProvider: VideoProvider;
}

export enum NewsblocDisplayMode {
  STANDARD = 'standard',
  ADVANCED = 'advanced',
}

export interface NewsblocSlice extends Slice {
  type: 'newsbloc';
  newsType?: ArticleType;
  newsTitle?: string;
  seeMoreNewsLabel?: string;
  displayMode?: NewsblocDisplayMode;
  articles: ArticleHead[];
  subThematics?: SubThematicsData[];
}

export interface PushArticlesSlice extends Slice {
  type: 'push_articles';
  title?: string;
  pushArticles: ArticleHead[];
}

export interface ButtonSlice extends Slice {
  type: 'button';
  buttons: Button[];
}

export interface SectionTextSlice extends Slice {
  type: 'section_text';
  title: string;
  description: string;
  subtitle: string;
  image: ImageWithMobile;
  button?: Link;
  link?: Link;
  isLandingPageIframe?: boolean;
}

export interface LegalMentionsSlice extends Slice {
  type: 'legal_mentions';
  richText: string;
}

export interface NoteSlice extends Slice {
  type: 'notes';
  notes: Note[];
}

export interface MentionCreditSlice extends Slice {
  type: 'mention_credit';
  description?: string;
}

export interface CharacteristicsKeysSlice extends Slice {
  type: 'characteristics_keys';
  title?: string;
  characteristicsKeys: CharacteristicsKeysItem[];
}

export interface CharacteristicsSlice extends Slice {
  type: 'characteristics';
  title?: string;
  description?: string;
}

export interface ArgumentsSlice extends Slice {
  type: 'arguments';
  title?: string;
  button?: Link;
  hasBackgroundColor: boolean;
  argumentItems: Argument[];
}

export interface TableSlice extends Slice {
  type: 'table';
  title?: string;
  subtitle?: string;
  anchor?: string;
  tableTitles?: TableTitle[];
  tableContent?: Partial<TableItem>[];
  tableCategories?: string[];
}

export interface OffersCarouselSlice extends Slice {
  type: 'offers_carousel';
  title: string;
  offerItems: OfferCarouselItem[];
}

export interface HeroSlice extends Slice {
  type: 'hero';
  title?: string;
  subtitle?: string;
  image?: ImageWithMobile;
  description?: string;
  buttons?: Button[];
}

export interface ProductHeroSlice extends Slice {
  type: 'page_produit_gal';
  title?: string;
  subtitle?: string;
  image?: ImageWithMobile;
  description?: string;
  buttons?: Button[];
}

export interface SuperHeroSlice extends Slice {
  type: 'super_hero';
  title?: string;
  title_color?: string;
  title_size?: number;
  subtitle?: string;
  subtitle_color?: string;
  subtitle_size?: number;
  label_size?: number;
  label_color?: string;
  richText?: string;
  text_color?: string;
  text_size?: number;
  image?: ImageWithMobile;
  buttons?: Button[];
  button_color?: string;
  button_color_2?: string;
  text_color_2?: string;
  text_size_2?: number;
  label_size_2?: number;
  label_color_2?: string;
  campaignCode?: string;
  persoContext?: string;
  megaHero: boolean;
}

export interface MagHeroSlice extends Slice {
  type: 'mag_hero';
  title?: string;
  subtitle?: string;
  image?: ImageWithMobile;
  articles: ArticleHead[];
}

export interface LandingPageEventHeroSlice extends Slice {
  type: 'landing_page_event_hero';
  title: string;
  subtitle: string;
  accroche?: string;
  event_banner: ImageWithMobile;
  event_logo?: ImageWithMobile;
  logo_link?: Link;
}

export interface AgencyMapHeroSlice extends Slice {
  type: 'agency_map_hero';
  title?: string;
  subtitle?: string;
  image?: ImageWithMobile;
}

export interface LandingUniversGalSlice extends Slice {
  type: 'landing_univers_gal';
  title?: string;
  subtitle?: string;
  richText?: string;
  image?: ImageWithMobile;
  buttons?: Link[];
  campaignCode?: string;
  persoContext?: string;
  megaHero: boolean;
}

export interface Agency {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  town: string;
  country: string;
  phoneNumber: string;
  fax: string;
  agencyType: AGENCY_TYPE;
  hasVault: boolean;
  latitude: number;
  longitude: number;
  distance: number;
  timezone: string;
  hours: AgencyHours;
  closedDates: ClosedDates[];
  communicationArea: string;
}

export interface AgencyHours {
  monday: HoursDetails;
  tuesday: HoursDetails;
  wednesday: HoursDetails;
  thursday: HoursDetails;
  friday: HoursDetails;
  saturday: HoursDetails;
  sunday: HoursDetails;
}

export interface HoursDetails {
  isOpen: boolean;
  openingHours?: OpeningHours;
}

export interface OpeningHours {
  amOpen?: string;
  amClose?: string;
  pmOpen?: string;
  pmClose?: string;
}

export interface ClosedDates {
  end: string;
  begin: string;
}

export type AGENCY_TYPE = 'BAP' | 'BP' | 'PRO';

export interface AgencyDetailsSlice extends Slice {
  type: 'agency_details';
  title?: string;
  subtitle?: string;
  image?: ImageWithMobile;
  agencyDetails?: Agency;
}

export interface ProjectBlockCtaCSlice extends Slice {
  type: 'project_block_cta';
  title?: string;
  projectBlocks?: ProjectBlock[];
}

export interface PushLinksSlice extends Slice {
  type: 'push_links';
  title?: string;
  description?: string;
  image?: ImageWithMobile;
  links?: Link[];
}

export interface PushProductsSlice extends Slice {
  type: 'push_products';
  title?: string;
  description?: string;
  image?: ImageWithMobile;
  pushProductsGroups?: PushProductsGroup[];
}

export interface StepperSlice extends Slice {
  type: 'stepper';
  title?: string;
  subtitle?: string;
  button?: Link;
  steps: string[];
}

export interface PdfLinksSlice extends Slice {
  type: 'pdf_links';
  alignment: Alignment;
  items: Link[];
}

export interface AppsAdSlice extends Slice {
  type: 'apps_ad';
  title?: string | null;
  anchor?: string | null;
  appsAdImages: {
    app: ImageWithMobile;
    year: ImageWithMobile;
    picture: ImageWithMobile;
  };
  description: string;
  appStores: ImageWithLink[];
}

export interface SimulatorSlice extends Slice {
  type: 'simulator';
  simulatorType: string;
  url: string;
  mobileIsDifferent: boolean;
  description: string;
  height: number;
}

export interface SolutionProductsSlice extends Slice {
  type: 'solution_products';
  title?: string;
  solutionProducts?: SolutionProduct[];
}

export interface MultiLinksSlice extends Slice {
  type: 'multi_links';
  title?: string | null;
  subtitle: string;
  simulatorDirectories: SimulatorDirectory[];
}

export interface CarouselServicesSlice extends Slice {
  type: 'carousel_services';
  title: string;
  carouselServicesItems: CarouselServicesItem[];
}

export interface PushParcoursSlice extends Slice {
  type: 'push_parcours';
  title: string;
  subtitle: string;
  button: Link | undefined;
  image: ImageWithMobile;
  richText?: string;
}

export interface FrontPageNewsSlice extends Slice {
  type: 'front_page_news';
  title: string;
  subtitle: string;
  description: string;
  mobileDescription: string;
  link: Link | null;
  image: ImageWithMobile;
}

export interface ContactUsSlice extends Slice {
  type: 'contact_us';
  contactUsTabs: ContactUsTab[];
}

export interface SummarySlice extends Slice {
  type: 'summary';
  title?: string | undefined;
  richText: string;
}

export interface SitemapSlice extends Slice {
  type: 'sitemap';
  sitemapColumns: SitemapItem[][];
}

export interface OfferBlockSlice extends Slice {
  type: 'offer_block';
  anchor: string | null;
  offerBlock: OfferBlock;
  button?: Link;
  offerPhone: OfferPhone;
}

export interface FaqAccordionSlice extends Slice {
  type: 'faq_accordion';
  anchor?: string;
  questions: FaqQuestion[];
}

export interface InterstitialChoicesSlice extends Slice {
  type: 'interstitial_choices';
  title: string;
  subtitle: string;
  image: ImageWithMobile;
  buttons: Link[];
}

export interface FormSlice extends Slice {
  title?: string;
}

export interface NonCustomerComplaintSlice extends FormSlice, Slice {
  type: 'non_customer_complaint';
  title?: string;
}

export interface EntrepriseContactFormSlice extends FormSlice, Slice {
  type: 'entreprise_contact_form';
  title?: string;
}

export interface ProContactFormSlice extends FormSlice, Slice {
  type: 'pro_contact_form';
  title?: string;
}

export interface NewAngleFormSlice extends FormSlice, Slice {
  type: 'angle_neuf';
  title?: string;
  recipt: Text[];
  title_placeholder_relation: string;
  select_relation: string[];
  title_placeholder_client_type: string;
  select_client_type: string[];
  text_radio_button: string;
  text_rgpd: string;
  email_redirection: string;
}

export interface AssistanceFormSlice extends FormSlice, Slice {
  type: 'assistance_form';
  title?: string;
}

export interface ReclamationFormSlice extends FormSlice, Slice {
  type: 'reclamation_form';
  title?: string;
  recipt: Text[];
  checkbox_reclamation_1: string;
  checkbox_reclamation_2: string;
  title_placeholder_relation: string;
  select_relation: string[];
  title_placeholder_client_type: string;
  select_type: string[];
  lien_client: string[];
  title_placeholder_message: string;
  liste_message: string[];
  under_jointpiece: string;
  notice_rgpd: string;
  email_redirection: string;
}

export interface LoanInsuranceFormSlice extends FormSlice, Slice {
  type: 'loan_insurance_form';
  title?: string;
}

export interface ReclamationConnectedCustomerFormSlice extends FormSlice, Slice {
  type: 'reclamation_connected_customer_form';
  title?: string;
}

export interface ResiliationFormSlice extends FormSlice, Slice {
  type: 'resiliation1';
  title?: string;
  product_types?: FamilleProduit[];
  recipt: Text[];
}

export interface QuestionFormSlice extends FormSlice, Slice {
  type: 'poser_question';
  title?: string;
  recipt: Text[];
  titre_placeholder_relation: string;
  select_relation: string[];
  titre_placeholder_client_type: string;
  select_client_type: string[];
  lien_client: string[];
  title_placeholder_message: string;
  liste_message: string[];
  under_jointpiece: string;
  text_rgpd: string;
  email_redirection: string;
}
export interface FamilleProduit {
  type_name?: string;
  iframe?: DocumentLink;
  products?: Produit[];
}

export interface Produit {
  product_code?: string;
  product_name?: string;
  informations?: string;
  optional_reference?: boolean;
  help_optional_reference?: string;
  resiliation_date: boolean;
}

export interface ListeDeProduits {
  product_code: string;
  product_name: string;
  informations: Text[];
  optional_reference: boolean;
  help_optional_reference: Text[];
  resiliation_date: boolean;
}

export interface DocumentLink {
  uid?: string;
  link_type: string;
  url?: string;
}

export interface LinksProductsBlockSlice extends Slice {
  type: 'links_products_block';
  title?: string;
  linkProductBlockItems: LinkWithDescription[];
  link?: Link;
  button?: Link;
}

export interface TimelineSlice extends Slice {
  type: 'timeline';
  title?: string;
  timelineSteps: TimelineStep[];
  action?: TimelineAction;
}

export interface SocialEmbedsSlice extends Slice {
  type: 'social_embeds';
  title?: string;
  socialEmbeds: SocialEmbed[];
}

export interface LandingPageHeroSlice extends Slice {
  type: 'landing_page_hero';
  title: string;
  subtitle: string;
  image: ImageWithMobile;
  buttonPrimary: Link;
  buttonSecondary?: Link;
}

export interface LandingPageBlockType1Slice extends Slice {
  type: 'landing_page_block_type1';
  image: ImageWithMobile;
  imageOnLeft: boolean;
  title: string;
  isImmo: boolean;
  description: string;
  button?: Link;
}

export interface LandingPageBlockType2Slice extends Slice {
  type: 'landing_page_block_type2';
  image: ImageWithMobile;
  imageOnLeft: boolean;
  title: string;
  isImmo: boolean;
  description: string;
  button?: Link;
}

export interface LandingPageKeyInfoSlice extends Slice {
  type: 'landing_page_key_info';
  content: string;
}

// endregion

export interface Page {
  id: string;
  uid: string;
  type: PageType;
  url?: string;
  data: Data;
  lang?: string | undefined;
  alternateLanguages?: AlternateLanguages[];
  error?: boolean;
}

export interface Link {
  label?: string | undefined;
  type?: string;
  url?: string | null;
  target?: string | null;
  buttonLinkData?: ButtonLinkData | undefined;
}

export interface LinkWithDescription extends Link {
  description: string;
}

export interface LinkWithImage extends Link {
  image: ImageWithMobile;
}

export interface ButtonLinkData {
  desktopBackgroundColor?: string | undefined;
  desktopIcon?: ImageWithMobile | undefined;
  desktopLabelColor?: string | undefined;
  mobileBackgroundColor?: string | undefined;
  mobileIcon?: ImageWithMobile | undefined;
  mobileLabelColor?: string | undefined;
}

export interface PageContent {
  type?: PageContentType;
  body: Slice[];
}

export interface DynamicPage extends PageContent {
  type?: 'DynamicPage';
}

export interface SimplePage extends PageContent {
  type?: 'SimplePage';
  title: string;
  subtitle?: string;
  backButtonLabel?: string;
  backButtonLink?: Link;
}

export interface Article extends PageContent {
  type?: 'Article';
  head?: ArticleHead;
}

export interface FaqPage extends PageContent {
  type?: 'FaqPage';
  uid?: string;
  title?: string | undefined;
  text?: string;
  image?: ImageWithMobile;
  questionsTitle?: string | undefined;
  questions?: FaqQuestion[];
}

export interface FaqQuestion {
  name?: string | undefined;
  text?: string | undefined;
  answer?: string | undefined;
}

export interface Argument {
  image: ImageWithMobile;
  text?: string;
  link?: Link;
  subtitle?: string;
}

export interface OfferCarouselItem {
  title: string;
  image: ImageWithMobile;
  link: Link;
  campaignCode?: string;
  vignette?: OfferVignette;
}

export interface CharacteristicsKeysItem {
  title: string;
  text: string;
}

export interface Seo {
  title?: string;
  description?: string;
  robots?: string;
  canonical?: string;
  openGraph: OpenGraph;
}

export interface OpenGraph {
  title?: string;
  description?: string;
  url?: string;
  type?: string;
  image?: ImageWithMobile;
}

export interface SubThematicsData {
  thematicId: string;
  subThematicLibele: string | null;
  prismicSubThematicLibele: string | null;
}

export interface Document {
  id: string;
  uid: string;
  type: string;
  href?: string;
  tags?: string[];
  slug?: string;
  slugs?: string[];
  lang?: string;
  alternate_languages?: AlternateLanguages[];
  first_publication_date?: string | null;
  last_publication_date?: string | null;
  data: PrismicData;
}

export interface DocumentRef {
  id: string;
  type: string;
  tags: string[];
  slug: string;
  lang: string;
  uid: string;
  link_type: string;
  isBroken: boolean;
}

export interface SubThematicDocumentRef {
  id: string;
  type: string;
  tags: string[];
  slug: string;
  lang: string;
  uid: string;
  link_type: string;
  isBroken: boolean;
  libele?: string;
}

export interface AlternateLanguages {
  id: string;
  uid: string;
  type: string;
  lang: string;
}

export interface Image {
  dimensions?: {
    width: number;
    height: number;
  };
  alt?: string | null;
  copyright?: string | null;
  url: string;
}

export interface ImageWithMobile extends Image {
  mobile?: Image;
}

export interface Content {
  type?: string;
  text?: string;
  spans?: Span[];
  anchor?: string;
  url?: string;
  alt?: string;
  copyright?: string;
  dimensions?: {
    width: number;
    height: number;
  };
  linkTo?: SpanData;
}

export interface Span {
  start: number;
  end: number;
  type: SpanType;
  data?: SpanData;
}

export interface SpanData {
  id?: string;
  url?: string;
  target?: string;
  label?: string;
  isBroken?: boolean;
  link_type?: string;
}

export interface Text {
  text: string;
  type?: string;
  spans: Span[];
}

export interface Breadcrumb {
  links: Link[];
}

export interface Button extends Link {
  label_size?: number;
  label_color?: string;
  button_color?: string;
}

export interface PrismicTimelineStep {
  titre_etape: string;
  description_etape: Content[];
  url_cible: PrismicLink;
  image_desktop: Image;
  image_mobile: Image;
  image_mobile_supplementaire: Image;
}

export interface Data {
  breadcrumb?: Breadcrumb;
  market?: string | null;
  universe?: string | null;
  image?: ImageWithMobile;
  link?: Link;
  seo?: Seo;
  homepageId?: string;
  pageContent?: PageContent | null;
  pushBanner?: PushBanner;
}

export interface PrismicData {
  description_thematique_faq?: Content[];
  url_bouton?: PrismicLink;
  url_bouton_2?: PrismicLink;
  libelle_bouton?: string;
  libelle_bouton_2?: string;
  news?: {
    head?: ArticleHead;
  };
  titre?: Text[];
  image?: ImageWithMobile;
  visuel_description?: ImageWithMobile;
  text?: Text[];
  texte?: Text[];
  marche?: string | null;
  univers?: string | null;
  marche_rattachement?: DocumentRef;
  univers_rattachement?: DocumentRef;
  sous_thematique?: DocumentRef;
  thematique?: DocumentRef;
  header?: DocumentRef;
  footer?: DocumentRef;
  theme?: DocumentRef;
  sous_titre?: Text[];
  accroche?: Content[];
  accroche_courte?: string;
  caracteristiques_cles?: CharacteristicsKeys[];
  caracteristiques?: CharacteristicsContent[];
  content?: Content[] | PrismicSlice[];
  link?: Link;
  body?: PrismicSlice[];
  body1?: PrismicSlice[];
  body2?: PrismicSlice[];
  body3?: PrismicSlice[];
  body4?: PrismicSlice[];
  body5?: PrismicSlice[];
  nom_du_contenu_prismic?: string;
  libelle_sous_thematique?: string;
  titre_seo?: string;
  description_seo?: string;
  robots?: string;
  titre_exemple_credit?: Text[];
  texte_exemple_credit?: Text[];
  mention_credit?: string;
  mention_pedagogique_credit?: Text[];
  type_contenu?: PrismicArticleType;
  date_publication?: string | null;
  temps_lecture?: string;
  mentions_legales?: Content[];
  question?: Content[];
  reponse?: Content[];
  titre_questions_frequentes?: string;
  titre_contacts_presse?: string;
  image_contacts_presse?: ImageWithMobile;
  liste_contacts?: PrismicDataContactList;
  boutons_actu?: PrismicButtonLink[];
  homepageInstitutional?: any;
  liens_communication?: PrismicMenuItem[];
  liste_univers_de_besoin?: PrismicMenuItem[];
  liste_marches?: PrismicLabelLink[];
  libelle_menu_deroulant_marches?: string;
  titre_institutionnel_mobile?: string;
  url_canonique?: string;
  open_graph_titre?: string;
  open_graph_description?: string;
  open_graph_image?: ImageWithMobile;
  pdf_telechargement?: Link;
  activation_impression?: YesNo;
  activation_telechargement?: YesNo;
  activation_facebook?: YesNo;
  activation_twitter?: YesNo;
  activation_linkedin?: YesNo;
  activation_mail?: YesNo;
  configuration_tableau?: DocumentRef;
  liste_champs?: PrismicTableTitleItem[];
  zone_promotion?: PrismicBlocSizeType;
  listes_liens?: PrismicMegamenuLink[];
  titre_push_mobile?: string;
  ancre?: string | null;
  image_application?: ImageWithMobile;
  image_application_annee?: ImageWithMobile;
  image_illustration?: ImageWithMobile;
  liste_fonctionnalites?: Content[];
  url_application_mobile_android?: PrismicWebLink;
  image_application_mobile_ios?: Image;
  image_application_mobile_android?: Image;
  url_application_mobile_ios?: PrismicWebLink;
  libelle_simulateur?: string;
  url_simulateur?: PrismicLink;
  description?: Content[];
  libelle_lien?: string;
  url_lien?: PrismicLink;
  titre_onglet_contact?: Content[];
  titre_onglet_urgence?: Content[];
  contacts_colonne_gauche?: any;
  contacts_colonne_droite?: any;
  urgences_colonne_gauche?: any;
  urgences_colonne_droite?: any;
  titre_contact?: any;
  titre_onglet_questions?: any;
  titre_bloc_questions?: any;
  liste_questions?: PrismicQuestionReponse[];
  code_campagne?: string | null;
  nom_offre?: string | null;
  accroche_offre?: Content[];
  conditions_offre?: Content[];
  date_debut_offre?: string | null;
  date_fin_offre?: string | null;
  valeur_chiffree_offre?: string | null;
  type_offre?: string | null;
  condition_particuliere?: Content[];
  info_exceptionnelle?: Content[];
  page_interstitiel?: DocumentRef;
  interstitiel_url_bouton_client?: PrismicLink;
  interstitiel_url_bouton_non_client?: PrismicLink;
  sticky_titre?: string;
  sticky_libelle_lien?: string;
  sticky_url_lien?: PrismicLink;
  libelle_lien_retour?: string;
  lien_retour?: PrismicLink;
  doc_promo_surbrillance?: DocumentRef;
  titre_timeline?: string;
  liste_etapes?: PrismicTimelineStep[];
  type_bouton?: PrismicButtonType;
  titre_section_sous_timeline?: string;
  texte_section_sous_timeline?: Content[];
  libelle_question?: string;
  reponses?: Array<{ libelle_reponse: string; action_reponse: DocumentRef }>;
  logo?: Logos;
  url_logo?: DocumentRef;
  libelle_bouton_contact?: string;
  colors_choice1?: string | undefined;
  picto1?: ImageWithMobile;
  colors_libelle1?: string | undefined;
  colors_pictomobile1?: string | undefined;
  pictomobile1?: ImageWithMobile;
  colors_libellemobile1?: string | undefined;

  url_bouton_contact?: PrismicLink;
  libelle_2eme_bouton?: string;
  colors_choice2?: string | undefined;
  picto2?: ImageWithMobile;
  colors_libelle2?: string | undefined;
  colors_pictomobile2?: string | undefined;
  pictomobile2?: ImageWithMobile;
  colors_libellemobile2?: string | undefined;

  url_2eme_bouton?: PrismicLink;
  libelle_3eme_bouton?: string;
  colors_choice3?: string | undefined;
  picto3?: ImageWithMobile;
  colors_libelle3?: string | undefined;
  colors_pictomobile3?: string | undefined;
  pictomobile3?: ImageWithMobile;
  colors_libellemobile3?: string | undefined;

  articles?: PrismicArticleWrapper[];
  libelle_bouton_principal?: string;
  url_bouton_principal?: PrismicWebLink;
  libelle_bouton_secondaire?: string;
  url_bouton_secondaire?: PrismicWebLink;
  'sous-titre'?: Text[];
  contexte_perso?: string;
  event_banner?: ImageWithMobile;
  event_logo?: ImageWithMobile;
  event_accroche?: Text[];
  lien_logo?: DocumentRef;
  statut_smartapp?: boolean;
  code_campagne_smartapp?: string | null;
  description_smartapp?: Content[];
  image_banner?: Logos;
  lien_bouton?: PrismicWebLink;
  appears_on_page?: LinkedPages[];
  couleur_picker?: string | undefined;
  titre_tableau?: Text[];
  couleur_lisere?: string;
  couleur_fond_zone_1?: string;
  texte_lisere?: string;
  couleur_lisere_2?: string;
  tableau_picto_zone_1?: Array<{
    picto_zone_1: ImageWithMobile;
    texte_picto_zone_1: string;
  }>;
  couleur_fond_zone_2?: string;
  tableau_picto_zone_2?: Array<{
    picto_zone_2: ImageWithMobile;
    texte_picto_zone_2: string;
  }>;
  titre_promo?: string;
  texte_promo?: string;
  prix_offre?: Text[];
  description_tableau?: Text[];
  sous_titre_tableau?: string;
  avantage_offre?: AvantageOffre[];
  type?: Text[];
  products?: ListeDeProduits[];
  iframe?: DocumentLink;
  type_name?: Text[];
  taille_titre?: number;
  couleur_titre?: string;
  taille_sous_titre?: number;
  couleur_sous_titre?: string;
  couleur_libelle?: string;
  taille_libelle?: number;
  couleur_bouton?: string;
  couleur_texte?: string;
  taille_texte?: number;
  couleur_libelle_2?: string;
  taille_libelle_2?: number;
  couleur_bouton_2?: string;
  couleur_texte_2?: string;
  taille_texte_2?: number;
}

export interface LinkedPages {
  linked_page: DocumentRef;
}

export interface PrismicQuestionReponse {
  question_reponse: DocumentRef;
}

export interface PrismicMegamenuLink {
  lien: DocumentRef;
}

export interface PrismicDataContactItem {
  nom: string;
  telephone: string;
  adresse_mail: string;
}

// interface PrismicQuestionTimeline {
//   libelle: string;
//   reference: PrismicLink;
// }

export interface CharacteristicsKeys {
  titre_caracteristique_cle: string;
  texte_caracteristique_cle: Content[];
}

export interface CharacteristicsContent {
  intitule_caracteristique: Content[];
  texte_caracteristique: Content[];
}

export interface ArticleHead {
  title?: string;
  description?: string;
  type?: string;
  readingTime?: string;
  publishOn?: string;
  image?: ImageWithMobile;
  theme?: string;
  url?: string;
  pdfDownloadLink?: string;
  shareList?: ArticleShareType[];
  subThematic?: string;
}

export interface ContactsBlockIem {
  name?: string;
  phone?: string;
  email?: string;
}

export interface Note {
  richText: string;
  title: string;
}

export interface Item {
  icone: ImageWithMobile;
  texte_au_survol: string;
  lien: PrismicLink;
  libelle: string;
  picto: ImageWithMobile;
}

export interface ParagraphItem {
  sous_titre_paragraphe: Content[];
  texte_paragraphe: Content[];
}

export interface NoteItem {
  titre_des_notes: string;
  texte: Content[];
}

export interface PrismicTableTitleItem {
  api_id_champ: string;
  libelle_champ: Content[];
  description_champ: string;
}

export interface PrismicArgumentItem {
  sous_titre_argument: string;
  url_argument: PrismicLink;
  image_argument: ImageWithMobile;
  texte_argument: Content[];
}

export interface PrismicButtonLink {
  libelle: string;
  url: PrismicLink;
}

export interface PrismicWebLink {
  link_type: string;
  url: string;
  target?: string;
  kind?: string;
  name?: string;
  size?: string;
}

export type PrismicLink = DocumentRef | PrismicWebLink;

export interface PrismicLabelLink {
  libelle: string;
  lien: PrismicLink;
}

export interface PrismicMenuItem extends PrismicLabelLink {
  mega_menu: DocumentRef;
}

export interface FooterAccordion {
  title: string;
  accordionTextColor: string;
  links: LinkWithImage[];
}

export interface Footer {
  socialNetworks: LinkWithImage[];
  accordions: FooterAccordion[];
  bottomBarLinks: Link[];
  bottomBarTextColor: string;
}

export interface NewsroomItem {
  image?: ImageWithMobile;
  title?: string;
  link?: Link;
}

export interface Header {
  universes: MenuItem[];
  communications: MenuItem[];
  markets: Link[];
  market: string;
  marketsMenuLabel: string;
  mobileTitle: string;
  exceptionalInformation?: string | undefined;
  statutSmartApp?: boolean;
  codeCampagneSmartApp?: string | undefined;
  descriptionSmartApp?: string | undefined;
  imageBanner?: Logos;
  smartButtonLabel?: string;
  lienBouton?: PrismicWebLink;
  appearsOnPage?: LinkedPages[];
  logo?: Logos;
  logoUrl: Link | null;
  contactButtonUrl?: Link;
  colorButton1?: string | undefined;
  iconButton1?: ImageWithMobile;
  labelColorButton1?: string | undefined;
  colorMobileButton1?: string | undefined;
  iconMobileButton1?: ImageWithMobile;
  labelColorMobileButton1?: string | undefined;

  secondButtonUrl?: Link;
  colorButton2?: string | undefined;
  iconButton2?: ImageWithMobile;
  labelColorButton2?: string | undefined;
  colorMobileButton2?: string | undefined;
  iconMobileButton2?: ImageWithMobile;
  labelColorMobileButton2?: string | undefined;

  thirdButtonWording: string;
  colorButton3?: string | undefined;
  iconButton3?: ImageWithMobile;
  labelColorButton3?: string | undefined;
  colorMobileButton3?: string | undefined;
  iconMobileButton3?: ImageWithMobile;
  labelColorMobileButton3?: string | undefined;

  marketUid: string | null;
  pageId: string;
  couleurPicker: string | undefined;
}

export interface LinkedPages {
  linked_page: DocumentRef;
}

export interface Logos extends ImageWithMobile {
  sticky?: Image;
  mobileopen?: Image;
}

export interface ProjectBlock {
  title?: string;
  richText?: string;
  image?: ImageWithMobile;
  button?: Link;
  anchor?: string;
  anchorLabel?: string;
  size?: BlocSizeType;
}

export interface PushProductsGroup {
  title: string;
  products: LinkWithDescription[];
}

export interface Product {
  title?: string;
  description?: string;
  url?: string;
  anchor?: string;
  anchorLabel?: string;
  image?: ImageWithMobile;
  imageSize?: BlocSizeType;
  simulatorLink?: Link;
}

export interface SolutionProduct extends Product {
  linkedProducts?: Product[];
  complementContact?: string;
}

export interface PrismicPushProductItem {
  'sous-titre': string | null;
  produit: DocumentRef;
}

export interface MenuItem {
  link: Link;
  megamenu?: Megamenu;
}

export interface Megamenu {
  items: LinkWithDescription[];
  promoDoc?: LinkWithDescription;
  offerSize: BlocSizeType;
}

export interface TableTitle {
  id: string;
  label: string;
  description: string;
}

export interface TableItem {
  title?: string;
  image?: ImageWithMobile;
  link?: Link;
  categories?: string[];
}

export interface PrismicSolutionProductItem {
  titre_bloc_solution?: Text[];
  ancre?: string;
  libelle_ancre?: string;
  description_bloc_solution?: Content[];
  image_bloc_solution?: ImageWithMobile;
  taille_image_bloc_solution?: PrismicBlocSizeType;
  lien_fiche_produit_bloc_solution1?: DocumentRef;
  lien_fiche_produit_bloc_solution2?: DocumentRef;
  lien_fiche_produit_bloc_solution3?: DocumentRef;
  complement_contact_bloc_solution?: Text[];
}

export interface PrismicOffer {
  titre_offre: Content[];
  image_offre: ImageWithMobile;
  url_produit_offre: PrismicLink;
  code_campagne?: string;
  promotion_offre: DocumentRef;
}

export interface SimulatorDirectory {
  image: ImageWithMobile;
  category?: string | null;
  blocks: SimulatorBlock[];
}

export interface SimulatorBlock {
  title: string;
  links: Link[];
}

export interface CarouselServicesItem {
  image: ImageWithMobile;
  title: string;
  description: string;
  animation: {
    url: string;
    mobile: {
      url: string;
    };
  };
  campaignCode: string | null;
}

export type ContactUsTabType = 'contact' | 'faq';

export interface ContactUsTab {
  type: ContactUsTabType;
  title: string;
  leftBlocks?: ContactUsBlock[];
  rightBlocks?: ContactUsBlock[];
  questionsBlock?: QuestionsBlock;
}

export interface ContactUsBlock {
  title: string;
  elements: ContactUsBlockElement[];
}

export interface ContactUsBlockElement {
  image: ImageWithMobile;
  text: string;
  quote: string;
  items?: ContactUsBlockItem[];
}

export interface ContactUsBlockItem {
  text: string;
  phone: string;
  image: ImageWithMobile;
}

export interface QuestionsBlock {
  title: string;
  questions: FaqQuestion[];
  button?: Link | null;
}

export interface SitemapItem {
  title: string;
  univers: SitemapUnivers[];
  column: number;
}

export interface SitemapUnivers {
  label: string;
  url: string;
  products?: Link[];
}

export interface ImageWithLink {
  image: Image;
  link: Link;
}

export interface OfferBlock {
  campaignCode: string | null;
  name: string;
  longTitle: string;
  conditions: string | null;
  datePreText: string | null;
  dateText: string | null;
  valueNumber: string;
  valueUnit: ValueUnit;
  valueSubtext: string | null;
}

export interface OfferPhone {
  phone: string | null;
  subtext: string | null;
}

export interface PushBanner {
  title: string;
  link: Link;
}

export interface TimelineStep {
  title?: string;
  description?: string;
  link?: Link;
  desktopImage: Image;
  mobileImage: Image;
  mobileAdditionalImage?: Image;
}

export interface TimelineAction {
  title?: string;
  description?: string;
  button?: Link;
}

export interface SocialEmbed {
  html: string;
  provider: EmbedProvider;
}

export interface PrismicSliceNewsblocPrimary {
  titre_bloc_actus: Content[];
  type_actus: PrismicArticleType;
  type_affichage: string;
  libelle_bouton_voir_plus_actus: string;
}

export type PrismicSliceNewsBloc = PrismicSlice<PrismicSliceNewsblocPrimary>;

export type PrismicSliceInteractiveQuestionsBlock = PrismicSlice<PrismicSliceInteractiveQuestionsBlockPrimary>;

export interface PrismicSliceInteractiveQuestionsBlockPrimary {
  titre_bloc: Text[];
  image: Image;
  question_initiale: DocumentRef;
}

export type InteractiveQuestionAnswerAction = 'changeQuestion' | 'changePage';

export interface InteractiveQuestionsSlice extends Slice {
  type: 'interactive_questions';
  interactiveQuestionsBlock: InteractiveQuestionsBlock;
}

export interface InteractiveQuestionsBlock {
  title?: string;
  backgroundUrl?: string;
  firstQuestionId: string;
  questions: InteractiveQuestion[];
}

export interface InteractiveQuestion {
  id: string;
  label: string;
  answers: InteractiveQuestionAnswer[];
}

export interface InteractiveQuestionAnswer {
  label: string;
  action: InteractiveQuestionAnswerAction;
  value: string;
}

export interface PrismicArticleWrapper {
  article: DocumentRef;
}

export interface PageFilters {
  market?: string;
  universe?: string;
  marketOrUniverse?: string;
  universeOrTheme?: string;
  theme?: string;
}

export interface OfferVignette {
  name: string;
  valueNumber: string;
  valueUnit: ValueUnit;
  valueSubtext?: string;
  code?: string;
}

export type PrismicSliceLandingPageEventOfferList = PrismicSlice<any, PrismicSliceLandingPageEventOfferItem[]>;

export interface PrismicSliceLandingPageEventOfferItem {
  pastille_active: ImageWithMobile;
  pastille_inactive: ImageWithMobile;
  image_offre: ImageWithMobile;
  mention_credit?: string;
  titre_offre: Content[];
  sous_titre_offre: Content[];
  description_offre: Content[];
  zone_contact?: Content[];
  zone_contact_phone?: string;
  mention_contact?: string;
  libelle_bouton_cta?: string;
  lien_bouton_cta?: PrismicWebLink;
  libelle_lien_cta?: string;
  url_lien_cta?: PrismicWebLink;
  exemple_credit_evenementiel?: string;
  texte_exemple_credit?: Content[];
  mentions_legales_evenementiel?: Content[];
}

export interface LandingPageEventOfferListSlice extends Slice {
  type: 'landing_page_event_offer_list';
  offers: LandingPageEventOffer[];
}

export interface LandingPageEventOffer {
  activeCard: ImageWithMobile;
  inactiveCard: ImageWithMobile;
  image: ImageWithMobile;
  creditMention?: string;
  title: string;
  subtitle: string;
  description: string;
  contactArea?: string;
  contactAreaPhoneNumber?: string;
  contactMention?: string;
  contactButtonLabel?: string | null;
  contactButtonURL?: string | null;
  contactLinkLabel?: string;
  contactLinkURL?: string;
  exampleCreditTitle?: string;
  exampleCreditText?: string;
  legalMention?: string;
}

export interface ScratchAreaSlice {
  type: 'scratch_area';
  title: string;
  description: string;
  subtitle: string;
  image: ImageWithMobile;
  button?: Link;
  link?: Link;
}

export interface ZoneComparativeSlice extends Slice {
  type: 'zone_comparative';
  image_fond?: ImageWithMobile;
  tableau_comparatif?: TableauObjetComparatif[];
}

export interface TableauObjetComparatif {
  titre_tableau?: string;
  couleur_lisere?: string;
  couleur_fond_zone_1?: string;
  texte_lisere?: string;
  couleur_lisere_2?: string;
  tableau_picto_zone_1?: TableauPicto[];
  couleur_fond_zone_2?: string;
  tableau_picto_zone_2?: TableauPicto[];
  titre_promo?: string;
  texte_promo?: string;
  prix_offre?: string;
  description_tableau?: string;
  libelle_bouton?: string;
  url_bouton?: Link;
  sous_titre_tableau?: string;
  avantage_offre?: AvantageOffre[];
  libelle_lien?: string;
  url_lien?: Link;
}

export interface AvantageOffre {
  picto_avantage?: ImageWithMobile;
  libelle_avantage?: string;
  statut_avantage?: boolean;
}

export interface TableauPicto {
  picto_zone?: ImageWithMobile;
  picto_texte?: string;
}

export interface HeroCarouselSlice extends Slice {
  type: 'hero_carousel';
  timer?: number;
  items: HeroItems[];
}

export interface HeroItems {
  hero: SuperHeroSlice | null;
}

export interface LifeMomentSlice extends Slice {
  type: 'bloc_moment_de_vie';
  title_bloc?: Text;
  color_title?: string;
  image: ImageWithMobile;
  color_label?: string;
  color_icon?: string;
  color_button?: string;
  blocLinks: BlocLinks[];
}

export interface BlocLinks {
  label: string;
  link: Link;
}

export interface ExtractedQA {
  id: string;
  marche: string;
  produit: string;
  question: string;
  reponse: string;
  references?: ExtractQAReferences[];
  last_modification: string;
}

export interface ExtractQAReferences {
  page_web?: string;
  video?: string;
  image?: string;
}
