// tslint:disable-next-line
import { SliceLandingPageEventOfferListComponent } from './slice-landing-page-event-offer-list/slice-landing-page-event-offer-list.component';
import { SliceLandingPageEventHeroComponent } from './slice-landing-page-event-hero/slice-landing-page-event-hero.component';
import { SliceAgencyMapHeroComponent } from './slice-agency/slice-agency-map-hero/slice-agency-map-hero.component';
import { SliceComponentClass, SliceComponentClassMap } from './slices.tokens';

import { SliceAppsAdComponent } from './slice-apps-ad/slice-apps-ad.component';
import { SliceArgumentsComponent } from './slice-arguments/slice-arguments.component';
import { SliceButtonsComponent } from './slice-buttons/slice-buttons.component';
import { SliceCarouselServicesComponent } from './slice-carousel-services/slice-carousel-services.component';
import { SliceCharacteristicsComponent } from './slice-characteristics/slice-characteristics.component';
import { SliceCharacteristicsKeysComponent } from './slice-characteristics-keys/slice-characteristics-keys.component';
import { SliceContactUsComponent } from './slice-contact-us/slice-contact-us.component';
import { SliceContactsPresseComponent } from './slice-contacts-presse/slice-contacts-presse.component';
import { SliceCreditExampleComponent } from './slice-credit-example/slice-credit-example.component';
import { SliceFaqAccordionComponent } from './slice-faq-accordion/slice-faq-accordion.component';
import { SliceFrontPageNewsComponent } from './slice-front-page-news/slice-front-page-news.component';
import { SliceHeroComponent } from './slice-hero/slice-hero.component';
import { SliceInterstitialChoicesComponent } from './slice-interstitial-choices/slice-interstitial-choices.component';
import { SliceLegalMentionsComponent } from './slice-legal-mentions/slice-legal-mentions.component';
import { SliceMentionCreditComponent } from './slice-mention-credit/slice-mention-credit.component';
import { SliceMultiLinksComponent } from './slice-multi-links/slice-multi-links.component';
import { SliceNewsblocComponent } from './slice-newsbloc/slice-newsbloc.component';
import { SliceNewsroomComponent } from './slice-newsroom/slice-newsroom.component';
import { SliceNoteComponent } from './slice-note/slice-note.component';
import { SliceNonCustomerComplaintComponent } from './slice-forms/slice-non-customer-complaint/slice-non-customer-complaint.component';
import { SliceOfferBlockComponent } from './slice-offer-block/slice-offer-block.component';
import { SliceOffersCarouselComponent } from './slice-offers-carousel/slice-offers-carousel.component';
import { SliceParagraphComponent } from './slice-paragraph/slice-paragraph.component';
import { SlicePdfLinksComponent } from './slice-pdf-links/slice-pdf-links.component';
import { SliceProjectBlockCtaComponent } from './slice-project-block-cta/slice-project-block-cta.component';
import { SlicePushArticlesComponent } from './slice-push-articles/slice-push-articles.component';
import { SlicePushLinksComponent } from './slice-push-links/slice-push-links.component';
import { SlicePushParcoursComponent } from './slice-push-parcours/slice-push-parcours.component';
import { SlicePushProductsComponent } from './slice-push-products/slice-push-products.component';
import { SliceQuoteComponent } from './slice-quote/slice-quote.component';
import { SliceSectionTextComponent } from './slice-section-text/slice-section-text.component';
import { SliceSimulatorComponent } from './slice-simulator/slice-simulator.component';
import { SliceSitemapComponent } from './slice-sitemap/slice-sitemap.component';
import { SliceSocialEmbedsComponent } from './slice-social-embeds/slice-social-embeds.component';
import { SliceSolutionProductsComponent } from './slice-solution-products/slice-solution-products.component';
import { SliceStepperComponent } from './slice-stepper/slice-stepper.component';
import { SliceSummaryComponent } from './slice-summary/slice-summary.component';
import { SliceSuperHeroComponent } from './slice-super-hero/slice-super-hero.component';
import { SliceTableComponent } from './slice-table/slice-table.component';
import { SliceVideoComponent } from './slice-video/slice-video.component';
import { SliceLinksProductsBlockComponent } from './slice-links-products-block/slice-links-products-block.component';
import { SliceTimelineComponent } from './slice-timeline/slice-timeline/slice-timeline.component';
import { SliceInteractiveQuestionsBlockComponent } from './slice-interactive-questions-block/slice-interactive-questions-block.component';
import { SliceMagHeroComponent } from './slice-mag-hero/slice-mag-hero.component';
import { SliceEntrepriseContactFormComponent } from './slice-forms/slice-entreprise-contact/slice-entreprise-contact.component';
// tslint:disable-next-line
import { SliceProfessionalContactFormComponent } from './slice-forms/slice-professional-contact/slice-professional-contact.component';
import { SliceLandingPageHeroComponent } from './slice-landing-page-hero/slice-landing-page-hero.component';
import { SliceLandingPageBlockType1Component } from './slice-landing-page-block-type1/slice-landing-page-block-type1.component';
import { SliceLandingPageBlockType2Component } from './slice-landing-page-block-type2/slice-landing-page-block-type2.component';
import { SliceLandingPageKeyInfoComponent } from './slice-landing-page-key-info/slice-landing-page-key-info.component';
import { SliceAgencyDetailsComponent } from './slice-agency/slice-agency-details/slice-agency-details.component';
import { SliceAssistanceFormComponent } from './slice-forms/slice-assistance/slice-assistance.component';
import { SliceReclamationFormComponent } from './slice-forms/slice-reclamation/slice-reclamation.component';
import { SliceLandingUniverseGalComponent } from './slice-landing-univers-gal-hero/slice-landing-univers-gal-hero.component';
import { SliceProductHeroComponent } from './slice-product-hero/slice-product-hero.component';
import { SliceScratchAreaComponent } from './slice-scratch-area/slice-scratch-area.component';
import { SliceLoanInsuranceFormComponent } from './slice-forms/slice-loan-insurance-form/slice-loan-insurance.component';
import { SliceReclamationConnectedCustomerFormComponent } from './slice-forms/slice-reclamation-connected-customer/slice-reclamation-connected-customer.component';
import { SliceZoneComparativeComponent } from './slice-zone-comparative/slice-zone-comparative.component';
import { SliceHeroCarouselComponent } from './slice-hero-carousel/slice-hero-carousel.component';
import { SliceResiliationFormComponent } from './slice-forms/slice-resiliation/slice-resiliation.component';
import { SliceQuestionFormComponent } from './slice-forms/slice-question-form/slice-question-form.component';
import { SliceLifeMomentComponent } from './slice-life-moment/slice-life-moment.component';
import { SliceNewAngleFormComponent } from './slice-forms/slice-new-angle/slice-new-angle.component';

export const SLICE_COMPONENT_CLASS_BY_TYPE_MAP: SliceComponentClassMap = {
  apps_ad: SliceAppsAdComponent,
  arguments: SliceArgumentsComponent,
  button: SliceButtonsComponent,
  carousel_services: SliceCarouselServicesComponent,
  characteristics: SliceCharacteristicsComponent,
  characteristics_keys: SliceCharacteristicsKeysComponent,
  contact_us: SliceContactUsComponent,
  contacts_presse: SliceContactsPresseComponent,
  credit_example: SliceCreditExampleComponent,
  faq_accordion: SliceFaqAccordionComponent,
  front_page_news: SliceFrontPageNewsComponent,
  hero: SliceHeroComponent,
  interactive_questions: SliceInteractiveQuestionsBlockComponent,
  interstitial_choices: SliceInterstitialChoicesComponent,
  legal_mentions: SliceLegalMentionsComponent,
  agency_map_hero: SliceAgencyMapHeroComponent,
  agency_details: SliceAgencyDetailsComponent,
  mention_credit: SliceMentionCreditComponent,
  multi_links: SliceMultiLinksComponent,
  newsbloc: SliceNewsblocComponent,
  newsroom: SliceNewsroomComponent,
  non_customer_complaint: SliceNonCustomerComplaintComponent,
  entreprise_contact_form: SliceEntrepriseContactFormComponent,
  loan_insurance_form: SliceLoanInsuranceFormComponent,
  pro_contact_form: SliceProfessionalContactFormComponent,
  assistance_form: SliceAssistanceFormComponent,
  reclamation_form: SliceReclamationFormComponent,
  offers_carousel: SliceOffersCarouselComponent,
  notes: SliceNoteComponent,
  offer_block: SliceOfferBlockComponent,
  paragraph: SliceParagraphComponent,
  pdf_links: SlicePdfLinksComponent,
  project_block_cta: SliceProjectBlockCtaComponent,
  push_articles: SlicePushArticlesComponent,
  push_links: SlicePushLinksComponent,
  push_parcours: SlicePushParcoursComponent,
  push_products: SlicePushProductsComponent,
  quote: SliceQuoteComponent,
  section_text: SliceSectionTextComponent,
  simulator: SliceSimulatorComponent,
  sitemap: SliceSitemapComponent,
  social_embeds: SliceSocialEmbedsComponent,
  solution_products: SliceSolutionProductsComponent,
  stepper: SliceStepperComponent,
  summary: SliceSummaryComponent,
  super_hero: SliceSuperHeroComponent,
  table: SliceTableComponent,
  video: SliceVideoComponent,
  links_products_block: SliceLinksProductsBlockComponent,
  timeline: SliceTimelineComponent,
  mag_hero: SliceMagHeroComponent,
  landing_page_hero: SliceLandingPageHeroComponent,
  landing_page_block_type1: SliceLandingPageBlockType1Component,
  landing_page_block_type2: SliceLandingPageBlockType2Component,
  landing_page_key_info: SliceLandingPageKeyInfoComponent,
  landing_page_event_hero: SliceLandingPageEventHeroComponent,
  landing_univers_gal: SliceLandingUniverseGalComponent,
  page_produit_gal: SliceProductHeroComponent,
  landing_page_event_offer_list: SliceLandingPageEventOfferListComponent,
  scratch_area: SliceScratchAreaComponent,
  reclamation_connected_customer_form: SliceReclamationConnectedCustomerFormComponent,
  resiliation1: SliceResiliationFormComponent,
  poser_question: SliceQuestionFormComponent,
  zone_comparative: SliceZoneComparativeComponent,
  hero_carousel: SliceHeroCarouselComponent,
  bloc_moment_de_vie: SliceLifeMomentComponent,
  angle_neuf: SliceNewAngleFormComponent,
};

export const SLICE_COMPONENT_CLASS_STANDALONE_LIST: SliceComponentClass[] = [
  SliceAppsAdComponent,
  SliceArgumentsComponent,
  SliceButtonsComponent,
  SliceCharacteristicsComponent,
  SliceCharacteristicsKeysComponent,
  SliceCreditExampleComponent,
  SliceFrontPageNewsComponent,
  SliceHeroComponent,
  SliceProductHeroComponent,
  SliceInterstitialChoicesComponent,
  SliceLegalMentionsComponent,
  SliceMentionCreditComponent,
  SliceMultiLinksComponent,
  SliceNewsblocComponent,
  SliceNewsroomComponent,
  SliceNoteComponent,
  SliceOffersCarouselComponent,
  SliceParagraphComponent,
  SlicePdfLinksComponent,
  SlicePushArticlesComponent,
  SlicePushLinksComponent,
  SlicePushParcoursComponent,
  SlicePushProductsComponent,
  SliceQuoteComponent,
  SliceSectionTextComponent,
  SliceSimulatorComponent,
  SliceSitemapComponent,
  SliceStepperComponent,
  SliceSummaryComponent,
  SliceSuperHeroComponent,
  SliceVideoComponent,
  SliceLinksProductsBlockComponent,
  SliceInteractiveQuestionsBlockComponent,
  SliceMagHeroComponent,
  SliceLandingPageHeroComponent,
  SliceLandingPageBlockType1Component,
  SliceLandingPageBlockType2Component,
  SliceLandingPageKeyInfoComponent,
  SliceLandingUniverseGalComponent,
  SliceScratchAreaComponent,
  SliceHeroCarouselComponent,
  SliceLifeMomentComponent,
];

export const SLICE_COMPONENT_CLASS_LIST: SliceComponentClass[] = [
  ...SLICE_COMPONENT_CLASS_STANDALONE_LIST,
  SliceProjectBlockCtaComponent,
  SliceSolutionProductsComponent,
  SliceTableComponent,
  SliceTimelineComponent,
  SliceSocialEmbedsComponent,
];
