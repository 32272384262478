<cb-mini-title class="mini-title" *ngIf="data.title" [title]="data.title" [withFrame]="!isFirstSlice"></cb-mini-title>

<form [formGroup]="form" class="form" (ngSubmit)="checkRecaptcha()">
  <h2>Identité</h2>
  <div formFieldControl id="civilTitle">
    <fieldset>
      <legend>Civilité*</legend>
      <div formFieldRadioGroup class="form-field-control--half-size">
        <form-field-radio name="civilTitle" formControlName="civilTitle" value="Mme">
          <span class="ic ic-woman civil-icon"></span>
          Madame
          <div class="check-icon">
            <span class="ic-check"></span>
          </div>
        </form-field-radio>
        <form-field-radio name="civilTitle" formControlName="civilTitle" value="M" class="form-field-radio--right">
          <span class="ic ic-man civil-icon"></span>
          Monsieur
          <div class="check-icon">
            <span class="ic-check"></span>
          </div>
        </form-field-radio>
      </div>
      <form-field-error></form-field-error>
    </fieldset>
  </div>

  <div formFieldControl id="lastName" class="form-field-control--half-size">
    <label for="lastName"> Nom* </label>
    <input formControlName="lastName" name="lastName" type="text" placeholder="Dupont" formFieldInput />
    <form-field-error></form-field-error>
  </div>
  <div formFieldControl id="firstName" class="form-field-control--half-size form-field-control--right">
    <label for="firstName">Prénom* </label>
    <input placeholder="Antoine" type="text" name="firstName" formControlName="firstName" formFieldInput />
    <form-field-error></form-field-error>
  </div>

  <div formFieldControl id="email" class="form-field-control--half-size">
    <label for="email"> E-mail* </label>
    <input formControlName="email" name="email" type="email" formFieldInput placeholder="exemple@mail.com" />
    <form-field-error></form-field-error>
  </div>

  <div formFieldControl id="phone" class="form-field-control--half-size form-field-control--right">
    <label for="phone"> Numéro de téléphone* </label>
    <input
      formControlName="phone"
      name="phone"
      type="text"
      formFieldInput
      inputmode="numeric"
      pattern="[0-9]*"
      mask="00 00 00 00 00"
      placeholder="01 23 45 67 89"
    />
    <form-field-error></form-field-error>
  </div>

  <div formFieldControl id="postalCode" class="form-field-control--half-size--block">
    <label for="postalCode">Code postal* </label>
    <input
      placeholder="75011"
      formControlName="postalCode"
      name="postalCode"
      formFieldInput
      type="text"
      inputmode="numeric"
      pattern="[0-9]*"
      mask="00000"
    />
    <form-field-error></form-field-error>
  </div>

  <h2>Êtes-vous client LCL ?</h2>
  <div formFieldControl id="clientFunction" class="form-field-control--large-size">
    <fieldset>
      <legend>Votre relation avec LCL : *</legend>
      <form-field-select
        formFieldSelect
        [options]="clientFunctionOptions"
        [formControlName]="'clientFunction'"
        [selectError]="selectError"
        [placeholder]="data.title_placeholder_client_type"
        (selectionChange)="onClientFunctionChange($event)"
      ></form-field-select>
      <form-field-error></form-field-error>
    </fieldset>
  </div>

  <div
    formFieldControl
    id="clientCategory"
    class="form-field-control--half-size--block"
    *ngIf="form.get('clientFunction')?.value === 0"
  >
    <label for="clientCategory"> Je suis un client : * </label>
    <form-field-select
      formFieldSelect
      name="clientCategory"
      [options]="clientCategoryOptions"
      [placeholder]="data.title_placeholder_client_type"
      [formControlName]="'clientCategory'"
      [selectError]="selectError"
    ></form-field-select>
    <form-field-error></form-field-error>
  </div>

  <ng-container *ngIf="form.get('clientFunction')?.value === 0">
    <h2>Agence LCL</h2>
    <div class="agencies-search-box">
      <div>
        <div formFieldControl id="agencySearch" class="form-field-control--half-size">
          <app-agency-form
            id="agencySearch"
            (optionSelected)="searchNearAgencies($event)"
            [isChildForm]="true"
            [displayNoAgencyMessage]="this.showSearchAgenciesResults && this.agencies.length === 0"
          >
          </app-agency-form>
        </div>
      </div>
      <div
        *ngIf="showSearchAgenciesResults && agencies.length > 0 && !selectedAgency"
        formFieldControl
        class="agencies-result-container"
      >
        <label>Sélectionnez une agence dans la liste de résultats.</label>
        <div *ngIf="displayMandatoryAgencyMessage" class="agency-mandatory">Sélection obligatoire.</div>
        <div *ngIf="agencies.length === 0" class="agency-count">
          Aucune agence LCL trouvée. Veuillez refaire une recherche
        </div>
        <div class="agencies-container">
          <div
            *ngFor="let agency of agencies; let agencyIndex = index; let odd = odd"
            class="agency"
            [ngClass]="{ 'agency-odd': odd }"
            (click)="updateAgencyData(agency)"
          >
            <div class="agency-info-name">{{ agency.name }}</div>
            <div>{{ agency.address }} - {{ agency.postalCode }} {{ agency.town }}</div>
          </div>
        </div>
      </div>
      <div formFieldControl class="hide-item">
        <label for="agencyRef">
          Agence
          <input formControlName="agencyRef" name="agencyRef" formFieldInput type="text" />
        </label>
      </div>
    </div>
  </ng-container>

  <div formFieldControl class="hide-item">
    <label for="emailRedirection">
      <input formControlName="emailRedirection" name="emailRedirection" formFieldInput type="text" />
    </label>
  </div>

  <div formFieldControl class="hide-item">
    <label for="recipt">
      <input formControlName="recipt" name="recipt" formFieldInput />
    </label>
  </div>

  <h2>Message</h2>
  <div formFieldControl>
    <textarea
      id="message"
      formControlName="message"
      placeholder="Ecrivez votre message ici..."
      #messageField="formFieldInput"
      formFieldInput
    ></textarea>
    <div formFieldHint>
      {{ messageField.charCountLabel }}
    </div>
    <form-field-error></form-field-error>
  </div>

  <div class="text-checkbox-container">
    <span [class.checkbox-field-error--hidden]="_hideCheckboxError" class="checkbox-field--error"
      >Champ obligatoire</span
    >
    <div formFieldControl id="textRadioButton">
      <fieldset>
        <form-field-checkbox
          formControlName="textRadioButton"
          (valueChanged)="getCheckBoxValue($event)"
          class="filter is-open-saturday-checkbox"
        >
        </form-field-checkbox>
      </fieldset>
    </div>
    <span [innerHTML]="data.text_radio_button"></span>
  </div>

  <p class="mandatory-field-text">*Champs obligatoires</p>
  <p>
    {{ data.text_rgpd }}
  </p>

  <div class="button-wrapper">
    <cb-cta-btn-link
      class="button"
      [button]="button"
      [buttonWithoutLink]="true"
      [sliceType]="data.type"
      [disabled]="_buttonDisabled"
    ></cb-cta-btn-link>
    <form-field-error
      class="post-error"
      [class.shown]="_hasPostError"
      message="Erreur dans l'envoi de votre demande. Merci de réessayer ultérieurement"
    >
    </form-field-error>
    <p class="recaptcha-mentions">
      Ce site est protégé par reCAPTCHA. Les
      <a href="https://policies.google.com/privacy">règles de confidentialité</a>
      et les
      <a href="https://policies.google.com/terms">conditions d'utilisation</a>
      de Google s'appliquent.
    </p>
  </div>
</form>
