import { FormFieldCheckboxComponent } from '../../../../ui/molecules/form-field-checkbox/form-field-checkbox.component';
import { CLIENT_RELATION, NEW_ANGLE_FORM } from '@repo/shared/form-schemas/new-angle';
import { AgenciesService } from '../../../../services/agencies.service';
import { AgenciesData, AgenciesResult } from './../../../../services/agencies.type';
import { FORM_COMPONENT, VALIDATION_SCHEMA_CONFIG } from './../../../../../tokens';
import { LoggerService } from './../../../../logger/logger.service';
import { DOMService } from './../../../../services/dom.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Agency, NewAngleFormSlice, Link } from '@repo/shared';
import { SliceWithData } from '../../../../../typings';
import { AbstractFormComponent } from '../abstract-form-component';
import { SliceFormsService } from '../slice-forms.service';
import { Router } from '@angular/router';
import { JSONSchema7 } from 'json-schema';
import { RECAPTCHA_LANGUAGE, ReCaptchaV3Service } from 'ng-recaptcha';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '../../../../logger/logger';
import { AgencyFormComponent } from '../../slice-agency/agency-form/agency-form.component';
import { Subject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

export const ANGLE_NEUF_CONFIRMATION_PAGE_URL = 'confirmation-contacter-angle-neuf';

@Component({
  selector: 'slice-new-angle-form',
  templateUrl: './slice-new-angle.component.html',
  styleUrls: ['../slice-forms.scss', './slice-new-angle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VALIDATION_SCHEMA_CONFIG,
      useValue: NEW_ANGLE_FORM,
    },
    {
      provide: FORM_COMPONENT,
      useExisting: forwardRef(() => SliceNewAngleFormComponent),
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'fr',
    },
  ],
})
export class SliceNewAngleFormComponent
  extends AbstractFormComponent
  implements SliceWithData<NewAngleFormSlice>, OnInit, OnDestroy, AfterViewInit {
  @Input() data: NewAngleFormSlice;

  unSubcribe$ = new Subject();
  logger: Logger;
  form: FormGroup;
  selectedClientFunctionValue: number;
  clientFunctionOptions;
  clientCategoryOptions;
  selectError = false;
  _buttonDisabled = false;
  _hasPostError = false;
  _hideCheckboxError = true;
  agencies: Agency[] = [];
  showSearchAgenciesResults = false;
  isCheckboxChecked = false;
  @ViewChild(AgencyFormComponent) agencyFormComponent: AgencyFormComponent;
  @ViewChild(FormFieldCheckboxComponent) formFieldCheckboxComponent: FormFieldCheckboxComponent;
  selectedAgency: Agency;
  displayMandatoryAgencyMessage: boolean;
  private agencyQuerySubscription: Subscription;

  get cssDisplayForAgencyForm(): 'none' | null {
    const identityValue = this.form.get('clientFunction')?.value;
    return identityValue === 0 || identityValue === 2 ? null : 'none';
  }

  get button(): Link {
    return { label: 'Envoyer le formulaire' };
  }

  constructor(
    @Inject(VALIDATION_SCHEMA_CONFIG) protected readonly _newAngleFormValidationSchema: JSONSchema7,
    private readonly sliceFormsService: SliceFormsService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    readonly domService: DOMService,
    readonly elementRef: ElementRef,
    readonly loggerService: LoggerService,
    private readonly agenciesService: AgenciesService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(_newAngleFormValidationSchema, elementRef, domService, changeDetectorRef);
    this.logger = loggerService.get(SliceNewAngleFormComponent.name);
  }

  ngOnInit(): void {
    this.form = this.sliceFormsService.buildForm(this.getFormValidationSchema());
    this.clientFunctionOptions = this.data.select_relation;
    this.clientCategoryOptions = this.data.select_client_type;
    if (this.form) {
      this.form?.patchValue({ textRadioButton: false });
      this.form?.patchValue({ emailRedirection: this.data.email_redirection });
      this.form?.patchValue({ recipt: this.data.recipt });
    }
  }

  ngAfterViewInit(): void {
    if (this.agencyFormComponent) this.setAgencyQueryFormControl();
    this.form.addControl('recaptcha', new FormControl('', Validators.required));
    this.form?.get('textadioButton')?.enable();
  }

  ngOnDestroy(): void {
    this.unSubcribe$.next(null);
    this.unSubcribe$.unsubscribe();
    if (this.agencyQuerySubscription) {
      this.agencyQuerySubscription.unsubscribe();
    }
  }

  checkRecaptcha(): void {
    this.recaptchaV3Service.execute('newAngleForm').subscribe(
      token => {
        this.form?.patchValue({ recaptcha: token });
        this.submitForm();
      },
      error => {
        this.logger.error('Error trying to verify request (reCaptcha v3)', error);
      },
    );
  }

  onClientFunctionChange(selectedClientFunctionValue: number): void {
    if (this.form && selectedClientFunctionValue === CLIENT_RELATION.LCL_CLIENT) {
      this.form?.get('agencyRef')?.enable();
      this.form?.get('agencyQuery')?.enable();
      this.form?.get('clientCategory')?.enable();
      this.form?.get('agencyID')?.enable();
    }
    if (this.form && selectedClientFunctionValue === CLIENT_RELATION.NON_LCL_CLIENT) {
      this.form?.get('clientCategory')?.disable();
      this.form?.get('agencyRef')?.disable();
      this.form?.get('agencyQuery')?.disable();
      this.form?.get('agencyID')?.disable();
    }
    this.changeDetectorRef.detectChanges();
  }

  submitForm(): void {
    const { valid, value } = this.form;

    if (valid) {
      this._buttonDisabled = true;

      this.sliceFormsService
        .sendNewAngleContactForm(value)
        .pipe(takeUntil(this.unSubcribe$))
        .subscribe(
          () => {
            this.router.navigateByUrl(ANGLE_NEUF_CONFIRMATION_PAGE_URL);
          },
          (error: HttpErrorResponse) => {
            this.logger.error('Could not submit form data:', error);
            this._buttonDisabled = false;
            this._hasPostError = true;
            this.changeDetectorRef.detectChanges();
          },
        );
    } else if (!valid) {
      this.triggerFormFieldsStatus(this.form);
      this.hanldeCheckBoxErrorField();
      this.changeDetectorRef.detectChanges();
      this.focusFirstInvalidFormField();
    }
  }

  getCheckBoxValue(value: boolean): void {
    this._hideCheckboxError = true;
    this.form?.patchValue({ textRadioButton: value });
  }

  hanldeCheckBoxErrorField(): void {
    const isFormValid = this.form.valid;
    const isCheckboxChecked = this.form?.get('textRadioButton')?.value;
    this._hideCheckboxError = isFormValid || isCheckboxChecked;
  }

  setAgencyQueryFormControl() {
    this.form.addControl('agencyQuery', this.agencyFormComponent.agencyForm.get('query') as AbstractControl);
    const agencyQueryControl = this.agencyFormComponent.agencyForm.get('query') as FormControl;
    agencyQueryControl.setParent(this.form);
    agencyQueryControl.setValidators([
      this.sliceFormsService._getFieldValidator(
        (NEW_ANGLE_FORM.properties as any)['agencyQuery'] as JSONSchema7,
        'agencyQuery',
      ),
    ]);

    this.form?.statusChanges.subscribe(status => {
      this._buttonDisabled = status === 'INVALID';
    });

    agencyQueryControl.updateValueAndValidity();

    this.agencyQuerySubscription = this.initAgencyQuerySubscription();
  }

  searchNearAgencies(agenciesData: AgenciesData): void {
    this.form?.patchValue({
      agencyQuery: this.agencyFormComponent.agencyForm.get('query')?.value,
    });

    // resetting selected agency value
    this.form?.patchValue({ agencyRef: '' });
    this.selectedAgency = (null as unknown) as Agency;

    this.agenciesService.searchNearAgencies(agenciesData.agenciesFilter).subscribe(agenciesResult => {
      this.agencies = this.getOrderedAgenciesFromResult(agenciesResult);
      this.showSearchAgenciesResults = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  initAgencyQuerySubscription(): Subscription {
    return this.agencyFormComponent.agencyForm.get('query')?.valueChanges.subscribe(value => {
      if (!value) {
        this.showSearchAgenciesResults = false;
      }
    }) as Subscription;
  }

  private getOrderedAgenciesFromResult(agenciesResult: AgenciesResult): Agency[] {
    if (!agenciesResult || !agenciesResult.agencies) {
      return [];
    }
    return agenciesResult.agencies
      .sort((a, b) => {
        if (a.distance > b.distance) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        return 0;
      })
      .slice(0, 10);
  }

  updateAgencyData(agency: Agency): void {
    this.selectedAgency = agency;
    this.displayMandatoryAgencyMessage = false;
    this.form.patchValue({ agencyRef: ('0' + agency.id).slice(-5) });
    this.form.patchValue({ agencyID: agency.id });
    this.agencyFormComponent.agencyForm.patchValue({ query: agency.name.toUpperCase() });
  }
}
