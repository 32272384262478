import { Component, Input } from '@angular/core';

import { FooterAccordion, ImageWithMobile, Link, LinkWithImage } from '@repo/shared';
import { AnalyticsSubPaths } from '../../../services/tag-commander.service';
import { getImageTag } from '../../../helpers/file.helpers';

const LCL_LOGO_FOOTER = '/assets/images/lcl_logo_footer.svg';

@Component({
  selector: 'ui-footer',
  template: `
    <div class="networkFooters flex-container" *ngIf="socialNetworks && socialNetworks.length !== 0">
      <div class="group">
        <div *ngFor="let socialNetwork of socialNetworks.slice(0, 3)" class="oneSocialNetwork">
          <a
            [attr.href]="socialNetwork.url"
            [attr.target]="socialNetwork.target"
            analyticsOn
            [analyticsLabel]="getAnalyticLabel(socialNetwork.image)"
            [analyticsSubPath]="analyticsSubPath"
          >
            <img
              [attr.src]="socialNetwork.image.url"
              [attr.alt]="socialNetwork.image.alt"
              [attr.width]="socialNetwork.image.dimensions.width"
              [attr.height]="socialNetwork.image.dimensions.height"
            />
          </a>
        </div>
      </div>
      <div class="group">
        <div *ngFor="let socialNetwork of socialNetworks.slice(3)" class="oneSocialNetwork">
          <a
            [attr.href]="socialNetwork.url"
            [attr.target]="socialNetwork.target"
            analyticsOn
            [analyticsLabel]="getAnalyticLabel(socialNetwork.image)"
            [analyticsSubPath]="analyticsSubPath"
          >
            <img
              [attr.src]="socialNetwork.image.url"
              [attr.alt]="socialNetwork.image.alt"
              [attr.width]="socialNetwork.image.dimensions.width"
              [attr.height]="socialNetwork.image.dimensions.height"
            />
          </a>
        </div>
      </div>
    </div>

    <nav aria-label="Menu Footer" class="footer-menu flex-container" *ngIf="accordions && accordions.length !== 0">
      <div class="footer-logo-lcl">
        <img [attr.src]="lclLogoFooter" width="143" height="106" alt="lcl footer icon" />
      </div>
      <div *ngFor="let accordion of accordions; index as i" class="menu">
        <footer-menu-toggle
          [title]="accordion.title"
          [links]="accordion.links"
          [id]="i"
          [accordeonTextColor]="accordion.accordionTextColor"
        ></footer-menu-toggle>
      </div>
    </nav>

    <div class="bottomFooter flex-container" *ngIf="bottomBarLinks && bottomBarLinks.length !== 0">
      <div *ngFor="let link of bottomBarLinks; last as isLast" class="footer-bottom-item">
        <a
          [ngStyle]="{ color: bottomBarLinksColor, textDecoration: isBottomBarLinksUnderlinedOnHover }"
          [attr.href]="link.url"
          [attr.target]="link.target"
          [ngClass]="{ disabled: !link.url }"
          analyticsOn
          [analyticsLabel]="link.label"
          [analyticsSubPath]="analyticsSubPath"
          >{{ link.label }}</a
        >
        <i class="vertical-bar" *ngIf="!isLast"></i>
      </div>
    </div>
  `,
  styleUrls: ['./ui-footer.component.scss'],
})
export class UiFooterComponent {
  @Input() socialNetworks: LinkWithImage[];
  @Input() accordions: FooterAccordion[];
  @Input() bottomBarLinks: Link[];
  @Input() isBottomBarLinksUnderlinedOnHover = true;
  @Input() bottomBarLinksColor: '#FFFFFF';

  lclLogoFooter = LCL_LOGO_FOOTER;
  analyticsSubPath = AnalyticsSubPaths.Footer;

  getAnalyticLabel(image: ImageWithMobile): string {
    return getImageTag(image);
  }
}
