import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as Ajv from 'ajv';
import { JSONSchema7 } from 'json-schema';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../config.service';
import { ComplaintForm } from '@repo/shared/form-schemas/non-customer-complaint';
import { EntrepriseContactForm } from '@repo/shared/form-schemas/entreprise-contact';
import { ProContactForm } from '@repo/shared/form-schemas/pro-contact';
import { CustomerComplaintFormGroup } from '@repo/shared/form-schemas/reclamation-client';
import { LoanInsuranceFormGroup } from '@repo/shared/form-schemas/loan-insurance';
import { ConnectedCustomerComplaintFormGroup } from '@repo/shared/form-schemas/reclamation-connected-client';
import { ResiliationFormGroup } from '@repo/shared/form-schemas/resiliation-client';

const ajv = new Ajv();

@Injectable()
export class SliceFormsService {
  apiUrl: string;

  constructor(private readonly _http: HttpClient, configService: ConfigService) {
    this.apiUrl = configService.get<string>('API_PUBLIC_URL');
  }

  buildForm(validationSchema: JSONSchema7): FormGroup {
    const validationSchemaProperties = validationSchema?.properties || {};

    return new FormGroup(
      Object.keys(validationSchemaProperties).reduce((acc, key) => {
        return {
          ...acc,
          [key]: new FormControl('', this._getFieldValidator(validationSchemaProperties[key] as JSONSchema7, key)),
        };
      }, {}),
    );
  }

  sendNonCustomerComplaintForm(complaintForm: ComplaintForm): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/non-customer-complaint`, complaintForm);
  }

  sendEntrepriseContactForm(form: EntrepriseContactForm): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/entreprise-contact`, form);
  }

  sendProfessionalContactForm(form: ProContactForm): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/pro-contact`, form);
  }

  sendAssistanceForm(form: FormGroup): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/tech-assistance`, form);
  }

  sendNewAngleContactForm(form: FormGroup): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/new-angle-contact`, form);
  }

  sendReclamationForm(form: CustomerComplaintFormGroup): Observable<{}> {
    const formData: FormData = new FormData();

    if (form.request && form.request.files) {
      const files: File[] = form.request.files;

      for (const file of files) {
        formData.append('file', file);
      }
    }

    formData.append('identity', JSON.stringify(form.identity));
    formData.append('claim', JSON.stringify(form.claim));
    formData.append('request', JSON.stringify(form.request));

    return this._http.post(`${this.apiUrl}/forms/customer-complaint`, formData);
  }

  sendQuestionForm(form: CustomerComplaintFormGroup): Observable<{}> {
    const formData: FormData = new FormData();

    if (form.request && form.request.files) {
      const files: File[] = form.request.files;

      for (const file of files) {
        formData.append('file', file);
      }
    }

    formData.append('identity', JSON.stringify(form.identity));
    formData.append('claim', JSON.stringify(form.claim));
    formData.append('request', JSON.stringify(form.request));

    return this._http.post(`${this.apiUrl}/forms/asking-form`, formData);
  }

  sendLoanInsuranceForm(form: LoanInsuranceFormGroup): Observable<{}> {
    const formData: FormData = new FormData();

    if (form.request && form.request.files) {
      const files: File[] = form.request.files;

      for (const file of files) {
        formData.append('file', file);
      }
    }

    formData.append('identity', JSON.stringify(form.identity));
    formData.append('request', JSON.stringify(form.request));

    return this._http.post(`${this.apiUrl}/forms/loan-insurance`, formData);
  }

  sendReclamationConnectedCustomerForm(form: ConnectedCustomerComplaintFormGroup): Observable<{}> {
    const formData: FormData = new FormData();

    if (form.request && form.request.files) {
      const files: File[] = form.request.files;

      for (const file of files) {
        formData.append('file', file);
      }
    }

    formData.append('identity', JSON.stringify(form.identity));
    formData.append('claim', JSON.stringify(form.claim));
    formData.append('request', JSON.stringify(form.request));

    return this._http.post(`${this.apiUrl}/forms/connected-customer-complaint`, formData);
  }

  sendResiliationForm(form: ResiliationFormGroup): Observable<{}> {
    return this._http.post(`${this.apiUrl}/forms/resiliation`, form);
  }

  public _getFieldValidator(validationSchema: JSONSchema7 = {}, key: string): ValidatorFn {
    const validate = ajv.compile(validationSchema);

    return (control: AbstractControl): ValidationErrors | null => {
      const valid = validate(control.value);
      if (!valid) {
        const { keyword } = (validate?.errors && validate.errors[0]) || {};
        let message = 'Champ obligatoire';
        if (keyword === 'pattern' || keyword === 'type') {
          message = 'Format invalide';
          if (key === 'email') {
            message = 'Adresse mail non autorisée';
          }
        }
        if (keyword) {
          return { [keyword]: { message, actualValue: control.value } };
        }
      }
      return null;
    };
  }
}
